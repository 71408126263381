import type { DisplayThemeJSON } from '@clerk/types';
import type { BillingResponse } from './billing';
import type { Statuses } from './domains';
import type { Product } from './products';

export interface InstanceStatus {
  status: Statuses.Complete | Statuses.Incomplete;
}

export enum EnvironmentType {
  Production = 'production',
  Development = 'development',
  Staging = 'staging',
}

type CaptchaWidgetType = 'invisible' | 'smart';

export type ActiveDomain = {
  object: string;
  id: string;
  name: string;
  is_satellite: boolean;
  frontend_api_url: string;
  accounts_portal_url: string;
  development_origin: string;
};

export type ActiveAuthConfig = {
  captcha_enabled: boolean;
  captcha_widget_type?: CaptchaWidgetType;
  object: string;
  id: string;
  first_name: string;
  last_name: string;
  email_address: string;
  phone_number: string;
  username: string;
  password: string;
  identification_requirements: string[][];
  identification_strategies: string[];
  first_factors: string[];
  second_factors: string[];
  email_address_verification_strategies: string[];
  single_session_mode: boolean;
  enhanced_email_deliverability: boolean;
  test_mode: boolean;
  cookieless_dev: boolean;
  url_based_session_syncing: boolean;
};

export type Theme = DisplayThemeJSON;

export type ActiveDisplayConfig = {
  object: string;
  id: string;
  instance_environment_type: string;
  application_name: string;
  theme: Theme;
  preferred_sign_in_strategy: string;
  logo_image_url: string;
  favicon_image_url: string;
  home_url: string;
  sign_in_url: string;
  sign_up_url: string;
  user_profile_url: string;
  after_sign_in_url: string;
  after_sign_up_url: string;
  after_sign_out_one_url: string;
  after_sign_out_all_url: string;
  after_switch_session_url: string;
  organization_profile_url: string;
  create_organization_url: string;
  after_leave_organization_url: string;
  after_create_organization_url: string;
  logo_link_url: string;
  support_email: string | null;
  branded: boolean;
  experimental_force_oauth_first: boolean;
  clerk_js_version: string;
  captcha_public_key: string | null;
  logo_url: string | null;
  favicon_url: string | null;
  logo_image: string | null;
  favicon_image: string | null;
  home_path: string | null;
  default_home_url: string;
  sign_in_path: string | null;
  default_sign_in_url: string;
  sign_up_path: string | null;
  default_sign_up_url: string;
  user_profile_path: string | null;
  default_user_profile_url: string;
  after_sign_in_path: string | null;
  default_after_sign_in_url: string;
  after_sign_up_path: string | null;
  default_after_sign_up_url: string;
  after_sign_out_one_path: string | null;
  default_after_sign_out_one_url: string;
  after_sign_out_all_path: string | null;
  default_after_sign_out_all_url: string;
  after_switch_session_path: string | null;
  default_after_switch_session_url: string;
  organization_profile_path: string | null;
  default_organization_profile_url: string;
  create_organization_path: string | null;
  default_create_organization_url: string;
  after_create_organization_path: string | null;
  default_after_create_organization_url: string;
  after_leave_organization_path: string | null;
  default_after_leave_organization_url: string;
  logo_link_path: string | null;
  default_logo_link_url: string;
};

type UserExport = {
  in_progress: boolean;
  status: 'queued' | 'in_progress' | '';
  latest_generated_at: number;
  expires_at: number;
};

export type BaseInstance = {
  id: string;
  application_id: string;
  environment_type: EnvironmentType;
  home_origin: string | null;
  created_at: number;
  updated_at: number;
  active_domain: ActiveDomain;
  active_auth_config: ActiveAuthConfig;
  active_display_config: ActiveDisplayConfig;
  user_export: UserExport;
};

export type Instance = BaseInstance & {
  blocked_country_codes: string[];
  premium_features: string[];
  supported_features: string[];
  auth_emails_from?: string;
  session_token_template_id?: string | null;
  from_email_domain_name: string;
  patch_me_password_state: string;
  features: Record<string, InstanceFeature>;
  billing: BillingResponse | null;
  has_users: boolean;
  dev_monthly_sms_limit: number | null;
  max_allowed_saml_connections: number;
  jwt_templates: InstanceJwtTemplate[] | null;
};

export type InstanceJwtTemplate = {
  name: string;
  cookie_overflows: number;
};

export type InstanceFeature = {
  key: string;
  is_supported: boolean;
  plan: InstanceFeaturePlan | null;
  is_in_grace_period: boolean;
};

export type InstanceFeaturePlan = Pick<Product, 'id' | 'name' | 'is_addon'>;

export enum InstanceKeyObject {
  InstanceKey = 'instance_key',
  PublicKey = 'public_key',
  PublicKeyPEM = 'public_key_pem',
  FapiKey = 'fapi_key',
  SecretKey = 'secret_key',
}

export type InstanceKey = {
  id: string;
  instance_id: string;
  name: string;
  object: InstanceKeyObject;
  secret: string;
  created_at: number;
  updated_at: number;
};

export type WebhookSettings = {
  enabled: boolean;
  svix_url: string;
};

export type UpdateInstanceParams = {
  test_mode?: boolean;
  hibp?: boolean;
  enhanced_email_deliverability?: boolean;
  support_email?: string;
  clerk_js_version?: string;
  url_based_session_syncing?: boolean;
  development_origin?: string;
};

export interface InstanceDeployStatus {
  status: Statuses.Complete | Statuses.Incomplete;
}

export type OrganizationActions = {
  admin_delete: boolean;
};

export type OrganizationDomains = {
  enabled: boolean;
  enrollment_modes: string[];
  default_role: string;
};

export interface OrganizationSettings {
  enabled: boolean;
  max_allowed_memberships: number;
  admin_delete_enabled: boolean;
  domains_enabled: boolean;
  domains_enrollment_modes: string[];
  domains_default_role: string;
  creator_role: string;
  object: string;
  max_allowed_permissions: number;
  max_allowed_roles: number;
}

export type OrganizationSettingsProps = {
  enabled: boolean;
  max_allowed_memberships: number;
  admin_delete_enabled: boolean;
  domains_enabled: boolean;
  domains_enrollment_modes: string[];
  creator_role_id: string;
  domains_default_role_id?: string;
};

export type UpdateOrganizationSettingsParams =
  Partial<OrganizationSettingsProps>;

export type UpdatePatchMePasswordParams = {
  state: 'disabled' | 'hidden';
};

export type UpdateCommunicationParams = {
  blocked_country_codes: string[];
};
