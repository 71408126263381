import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import {
  createAutocomplete,
  type AutocompleteApi,
  type AutocompleteCollection,
  type AutocompleteState,
  type BaseItem,
} from '@algolia/autocomplete-core';
import {
  getAlgoliaResults,
  type HighlightResult,
  type SnippetResult,
} from '@algolia/autocomplete-preset-algolia';
import algoliasearch from 'algoliasearch/lite';
import { SubmenuTypes } from './CommandMenu';

function getItemUrl({ item }: { item: Item }) {
  if (item.url.startsWith('https://')) {
    return item.url;
  }
  if (item.url.startsWith('/')) {
    return `https://clerk.com${item.url}`;
  }
  return `https://clerk.com/docs/${item.url}`;
}

const client = algoliasearch(
  `${process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID}`,
  `${process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY}`,
);

function PageIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg viewBox='0 0 20 20' fill='none' aria-hidden {...props}>
      <path
        fill='currentColor'
        fillOpacity='0.15'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M3.75 4.75a2 2 0 0 1 2-2h5.5l5 5v7.5a2 2 0 0 1-2 2h-8.5a2 2 0 0 1-2-2V4.75Z'
      />
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M10.75 2.75v4.5a1 1 0 0 0 1 1h4.5'
      />
    </svg>
  );
}

function HashIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg viewBox='0 0 20 20' fill='none' aria-hidden {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M6.75 3.75v12.5M13.25 3.75v12.5M3.75 6.75h12.5M3.75 13.25h12.5'
      />
    </svg>
  );
}

function ContentIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg viewBox='0 0 20 20' fill='none' aria-hidden {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M3.75 3.75h12.5M3.75 16.25h12.5'
      />
      <path
        fill='currentColor'
        fillOpacity='0.15'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M2.75 7.75a1 1 0 0 1 1-1h12.5a1 1 0 0 1 1 1v4.5a1 1 0 0 1-1 1H3.75a1 1 0 0 1-1-1v-4.5Z'
      />
    </svg>
  );
}

type Hierarchy = 'lvl1' | 'lvl2' | 'lvl3' | 'lvl4' | 'lvl5' | 'lvl6';

interface Item extends BaseItem {
  objectID: string;
  index: number;
  path: string;
  url: string;
  type: 'content' | 'lvl1' | 'lvl2' | 'lvl3' | 'lvl4' | 'lvl5' | 'lvl6';
  content: string | null;
  hierarchy: {
    [key in Hierarchy]: string | null;
  };
  _snippetResult: SnippetResult<{ content: string }>;
  _highlightResult: HighlightResult<{
    hierarchy: { [key in Hierarchy]: string };
  }>;
}

export function CommandMenuDocsSearch({
  setOpen,
  setActiveMenu,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveMenu: React.Dispatch<SubmenuTypes>;
}) {
  const [autocompleteState, setAutocompleteState] =
    useState<null | AutocompleteState<Item>>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const autocomplete = useMemo(
    () =>
      createAutocomplete<
        Item,
        React.SyntheticEvent,
        React.MouseEvent,
        React.KeyboardEvent
      >({
        defaultActiveItemId: 0,
        onStateChange({ state }) {
          setAutocompleteState(state);
        },
        navigator: {
          navigate({ itemUrl }) {
            if (
              itemUrl ===
              window.location.pathname +
                window.location.search +
                window.location.hash
            ) {
              setOpen(false);
            } else {
              void window.open(itemUrl, '_blank');
            }
          },
        },
        shouldPanelOpen({ state }) {
          return state.query !== '';
        },
        getSources() {
          return [
            {
              sourceId: 'docs',
              getItemUrl,
              onSelect: () => {
                // Prevent panel from closing when selecting an item
              },
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient: client,
                  queries: [
                    {
                      indexName: `${process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME}`,
                      query,
                      params: {
                        hitsPerPage: 8,
                        highlightPreTag: '<mark>',
                        highlightPostTag: '</mark>',
                        attributesToSnippet: ['content:20'],
                        tagFilters: [
                          // Exclude Core 1 docs
                          '-core_1',
                        ],
                      },
                    },
                  ],
                });
              },
            },
          ];
        },
      }),
    [],
  );

  const inputRef = useRef(null);
  const inputProps = autocomplete.getInputProps({
    inputElement: inputRef.current,
  });

  useEffect(() => {
    // listen for enter and setopen to close

    const onKeyDown = (event: KeyboardEvent) => {
      if (
        event.key === 'Enter' &&
        autocompleteState &&
        autocompleteState.collections?.length > 0
      ) {
        setOpen(false);
      }
    };

    wrapperRef.current?.addEventListener('keydown', onKeyDown);
    return () => wrapperRef.current?.removeEventListener('keydown', onKeyDown);
  }, [autocompleteState]);

  return (
    <div ref={wrapperRef}>
      <ol className='flex gap-1 p-2'>
        <li>
          <button
            className='flex h-5 items-center rounded-sm bg-legacyGray-100 px-1.5 text-sm text-legacyGray-700'
            onClick={() => {
              setActiveMenu('default');
            }}
          >
            Home
          </button>
        </li>
        <li>
          <button className='flex h-5 items-center rounded-sm bg-legacyGray-100 px-1.5 text-sm capitalize text-legacyGray-700'>
            Docs
          </button>
        </li>
      </ol>
      <div className='relative'>
        <input
          ref={inputRef}
          {...autocomplete.getRootProps({})}
          {...inputProps}
          onKeyDown={event => {
            if (event.key === 'Tab') {
              // Prevent panel from closing
              return;
            }

            if (event.key === 'Backspace' && inputProps.value === '') {
              setActiveMenu('default');
            }

            if (
              event.key === 'ArrowUp' &&
              autocompleteState?.activeItemId === 0
            ) {
              // Prevent looping
              event.preventDefault();
              return;
            }
            if (
              event.key === 'ArrowDown' &&
              autocompleteState?.collections[0].items.length &&
              autocompleteState?.activeItemId ===
                autocompleteState?.collections[0].items.length - 1
            ) {
              // Prevent looping
              event.preventDefault();
              return;
            }
            return inputProps.onKeyDown(event);
          }}
          autoFocus
          aria-autocomplete='list'
          placeholder='Search documentation'
          className='h-12 w-full appearance-none border-b px-4 text-base placeholder:text-tertiary focus:outline-none [&::-webkit-search-cancel-button]:appearance-none [&::-webkit-search-decoration]:appearance-none [&::-webkit-search-results-button]:appearance-none [&::-webkit-search-results-decoration]:appearance-none'
        />
        <button
          type='button'
          className='absolute right-3 top-3'
          onClick={() => setOpen(false)}
        >
          <svg
            viewBox='0 0 20 20'
            fill='none'
            aria-hidden='true'
            className='size-5 stroke-legacyGray-950'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='m6 6 8 8M14 6l-8 8'
            ></path>
          </svg>
        </button>
      </div>
      <div
        {...autocomplete.getPanelProps({})}
        onMouseLeave={() => {
          // Prevent resetting of active item to default
        }}
        className='max-h-96 scroll-p-2 overflow-y-auto p-2'
      >
        {autocompleteState?.isOpen &&
          autocompleteState.collections.map((collection, collectionIndex) => (
            <div key={collectionIndex}>
              {collection.items.length === 0 && (
                <div className='p-6'>
                  <p className='mt-4 text-center text-[0.875rem]/5 text-legacyGray-600 dark:text-legacyGray-400'>
                    Nothing found for{' '}
                    <span className='font-medium text-legacyGray-950 dark:text-white'>
                      “{autocompleteState?.query}”
                    </span>
                    .
                  </p>
                </div>
              )}
              {collection.items.length > 0 && (
                <div {...autocomplete.getListProps()}>
                  {collection.items.map(item => (
                    <Hit
                      key={item.objectID}
                      item={item}
                      autocomplete={autocomplete}
                      collection={collection}
                      onClick={() => {
                        if (
                          getItemUrl({ item }) ===
                          window.location.pathname +
                            window.location.search +
                            window.location.hash
                        ) {
                          setOpen(false);
                        }
                      }}
                      setOpen={setOpen}
                    />
                  ))}
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
}

function HighlightValue({ value }: { value: string }) {
  return value
    .split(/<mark>(.*?)<\/mark>/)
    .map((val, index) =>
      index % 2 === 0 ? val : <mark key={index}>{val}</mark>,
    );
}

function Hit({
  item,
  autocomplete,
  collection,
  onClick,
}: {
  item: Item;
  autocomplete: AutocompleteApi<
    Item,
    React.SyntheticEvent,
    React.MouseEvent,
    React.KeyboardEvent
  >;
  collection: AutocompleteCollection<Item>;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  let value: string | null | undefined = null;
  if (item.type === 'content') {
    value = item._snippetResult?.content?.value;
  } else {
    value = item._highlightResult?.hierarchy?.[item.type]?.value;
  }
  const href = getItemUrl({ item });

  if (!value) {
    return null;
  }

  const hierarchy: Hierarchy[] = item.type === 'lvl1' ? [] : ['lvl1'];
  const hierarchyValues = hierarchy
    .map(key => item._highlightResult?.hierarchy?.[key]?.value)
    .filter((value): value is string => typeof value === 'string');

  const Icon =
    item.type === 'content'
      ? ContentIcon
      : item.type === 'lvl1'
        ? PageIcon
        : HashIcon;

  return (
    <a
      {...autocomplete.getItemProps({
        item,
        source: collection.source,
      })}
      tabIndex={-1}
      href={href}
      rel='noopener noreferrer'
      target='_blank'
      className='flex min-h-10 -scroll-mt-px items-start gap-x-2.5 rounded-lg px-2 py-2.5 first:scroll-mt-0 aria-[selected=true]:bg-black/5 [&_mark]:relative [&_mark]:-z-10 [&_mark]:-mx-0.5 [&_mark]:rounded [&_mark]:bg-legacyGray-200 [&_mark]:px-0.5 [&_mark]:py-0.5 [&_mark]:font-medium [&_mark]:text-legacyGray-950 dark:[&_mark]:bg-white/10 dark:[&_mark]:text-white'
      onClick={onClick}
    >
      <Icon className='size-5 flex-none text-legacyGray-400 dark:text-legacyGray-500' />
      <div>
        {hierarchy.length > 0 && (
          <div
            className='mb-0.5 flex h-5 flex-wrap items-center gap-x-1.5 text-xs text-legacyGray-600 dark:text-legacyGray-500'
            aria-hidden
          >
            {hierarchyValues.map((hierarchyValue, hierarchyValueIndex) => (
              <Fragment key={hierarchyValueIndex}>
                {hierarchyValueIndex !== 0 && (
                  <svg
                    viewBox='0 0 10 10'
                    fill='none'
                    aria-hidden
                    className='h-5 w-2.5 flex-none'
                  >
                    <path
                      stroke='#B7B8C2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='m7.5.5-5 9'
                    />
                  </svg>
                )}
                <div className='isolate'>
                  <HighlightValue value={hierarchyValue} />
                </div>
              </Fragment>
            ))}
          </div>
        )}
        <div className='isolate text-legacyGray-950 dark:text-legacyGray-400'>
          <HighlightValue value={value} />
        </div>
      </div>
    </a>
  );
}
