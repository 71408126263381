import { IconProps } from '@/app/components/Icon';

export function SignatureIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M7.17475 12.5255L13.6908 6.00953C14.4364 5.26387 14.4364 4.05491 13.6908 3.30925C12.9451 2.56358 11.7361 2.56358 10.9905 3.30925L4.47446 9.82525C4.18619 10.1135 3.97058 10.4662 3.84542 10.8542L2.75 14.25L6.1458 13.1546C6.53379 13.0294 6.88647 12.8138 7.17475 12.5255Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M5.75 16.8626C6.1875 16.8626 8.19374 16.5924 8.71875 14.8813C9.31786 12.9288 10.7812 13.6748 10.7812 15.8136C10.7812 17.9523 11.1119 17.4915 12.6562 15.8136C14.625 13.6747 14.625 16.883 17.25 15.8136' />
        </>
      ) : (
        <>
          <path d='M13.25 13.25C12.5 13.25 11.8063 12.7088 11.2812 11.5438C10.625 10.0875 9.3125 10.8157 9.3125 12.272C9.3125 13.496 8.7627 13.4695 7.75 12.7515' />
          <path d='M6.40522 10.8254L11.788 5.44266C12.404 4.82667 12.404 3.82797 11.788 3.21199C11.172 2.596 10.1733 2.596 9.55734 3.21199L4.17455 8.59478C3.93642 8.83292 3.7583 9.12426 3.65491 9.44478L2.75 12.25L5.55522 11.3451C5.87574 11.2417 6.16708 11.0636 6.40522 10.8254Z' />
        </>
      )}
    </>
  );
}
