import { IconProps } from '@/app/components/Icon';

export function HourGlassIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M5.5 5.73479V2.75H14.5V5.73479C14.5 6.82429 13.9093 7.82815 12.9569 8.35726L10 10L12.9569 11.6427C13.9093 12.1718 14.5 13.1757 14.5 14.2652V17.25H5.5V14.2652C5.5 13.1757 6.09068 12.1718 7.04307 11.6427L10 10L7.04307 8.35726C6.09068 7.82815 5.5 6.82429 5.5 5.73479Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M3.5 2.75H5.5M16.5 2.75H14.5M3.5 17.25H5.5M16.5 17.25H14.5M5.5 2.75V5.73479C5.5 6.82429 6.09068 7.82815 7.04307 8.35726L10 10M5.5 2.75H14.5M14.5 2.75V5.73479C14.5 6.82429 13.9093 7.82815 12.9569 8.35726L10 10M14.5 17.25V14.2652C14.5 13.1757 13.9093 12.1718 12.9569 11.6427L10 10M14.5 17.25H5.5M5.5 17.25V14.2652C5.5 13.1757 6.09068 12.1718 7.04307 11.6427L10 10' />
        </>
      ) : (
        <>
          <path d='M3.25 2.75H4.75M4.75 2.75V4.88241C4.75 5.57693 5.11031 6.22173 5.7018 6.58573L8 8M4.75 2.75H11.25M11.25 2.75H12.75M11.25 2.75V4.88241C11.25 5.57693 10.8897 6.22173 10.2982 6.58573L8 8M11.25 13.25H12.75M11.25 13.25V11.1176C11.25 10.4231 10.8897 9.77827 10.2982 9.41427L8 8M11.25 13.25H4.75M3.25 13.25H4.75M4.75 13.25V11.1176C4.75 10.4231 5.11031 9.77827 5.70181 9.41427L8 8' />
        </>
      )}
    </>
  );
}
