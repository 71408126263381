import { SUPPORTED_FEATURES } from '@constants';

export const EnhancedAuthentication = 'enhanced_auth_2023_11';
export const EnhancedOrganizationControls = 'enhanced_orgs_2023_11';
export const EnhancedAdministration = 'enhanced_admin_2023_11';

export const MULTI_FACTOR_AUTH = 'Multi factor auth';
export const MULTI_DOMAIN_AUTHENTICATION = 'Multi-domain authentication';
export const SIMULTANEOUS_SESSIONS = 'Simultaneous sessions';
export const DEVICE_TRACKING_AND_REVOCATION = 'Device tracking and revocation';
export const ENTERPRISE_AUTHENTICATION = 'Enterprise SSO (EASIE, SAML, OIDC)';

export const USER_IMPERSONATION = 'User impersonation';
export const ENHANCED_ROLES = 'Enhanced roles';
export const AUDIT_LOGS = 'Audit logs';

export const VERIFIED_DOMAINS = 'Verified domains';
export const AUTOMATIC_INVITATIONS = 'Automatic invitations';
export const CUSTOM_ROLES_AND_PERMISSIONS = 'Custom roles & permissions';

export const SOC2_REPORT = 'SOC2 report';
export const ALLOW_LIST_BLOCK_LIST = 'Allowlist / Blocklist';
export const USER_BANS = 'User bans';
export const SMS_CODES = 'SMS codes';
export const CUSTOM_SESSION_DURATIONS = 'Custom session durations';
export const PASSWORD_COMPLEXITY = 'Password complexity requirements';
export const REMOVE_CLERK_BRANDING = 'Remove Clerk branding';
export const UNLIMITED_SOCIAL_CONNECTIONS = 'Unlimited social connections';
export const PASSKEYS = 'Passkeys';

export const MONTHLY_FREE_MAU = '10,000 monthly active users free';
export const MONTHLY_FREE_MAO = '100 monthly active organizations free';
export const FREE_SOCIAL_CONNECTION = '3 social connections';

export const MONTHLY_FREE_MAU_LEGACY_PLANS_MAU =
  '1,000 monthly active users free';

export const proFeatures = [
  REMOVE_CLERK_BRANDING,
  UNLIMITED_SOCIAL_CONNECTIONS,
  ALLOW_LIST_BLOCK_LIST,
  SMS_CODES,
  CUSTOM_SESSION_DURATIONS,
  USER_BANS,
  PASSWORD_COMPLEXITY,
  PASSKEYS,
];

export const planFeatures = {
  free: [MONTHLY_FREE_MAU, MONTHLY_FREE_MAO, FREE_SOCIAL_CONNECTION],
  legacy: {
    hobby: [MONTHLY_FREE_MAU_LEGACY_PLANS_MAU, MONTHLY_FREE_MAO],
    business: [MONTHLY_FREE_MAU_LEGACY_PLANS_MAU, MONTHLY_FREE_MAO],
  },
  pro: [...proFeatures],
};

export const addonFeatures = {
  [EnhancedAuthentication]: [
    MULTI_FACTOR_AUTH,
    MULTI_DOMAIN_AUTHENTICATION,
    SIMULTANEOUS_SESSIONS,
    DEVICE_TRACKING_AND_REVOCATION,
    ENTERPRISE_AUTHENTICATION,
  ],
  [EnhancedAdministration]: [USER_IMPERSONATION, ENHANCED_ROLES, AUDIT_LOGS],
  [EnhancedOrganizationControls]: [
    VERIFIED_DOMAINS,
    AUTOMATIC_INVITATIONS,
    CUSTOM_ROLES_AND_PERMISSIONS,
  ],
};

export const GROUPED_FEATURES = {
  [SUPPORTED_FEATURES.phone_code]: SMS_CODES,

  [SUPPORTED_FEATURES.mfa_totp]: MULTI_FACTOR_AUTH,
  [SUPPORTED_FEATURES.mfa_backup_code]: MULTI_FACTOR_AUTH,
  [SUPPORTED_FEATURES.mfa_phone_code]: MULTI_FACTOR_AUTH,

  [SUPPORTED_FEATURES.domains]: MULTI_DOMAIN_AUTHENTICATION,

  [SUPPORTED_FEATURES.multi_session]: SIMULTANEOUS_SESSIONS,

  [SUPPORTED_FEATURES.saml]: ENTERPRISE_AUTHENTICATION,

  [SUPPORTED_FEATURES.impersonation]: USER_IMPERSONATION,

  [SUPPORTED_FEATURES.organization_domains]: VERIFIED_DOMAINS,

  [SUPPORTED_FEATURES.password_complexity]: PASSWORD_COMPLEXITY,

  [SUPPORTED_FEATURES.allowlist]: ALLOW_LIST_BLOCK_LIST,
  [SUPPORTED_FEATURES.blocklist]: ALLOW_LIST_BLOCK_LIST,

  [SUPPORTED_FEATURES.custom_session_duration]: CUSTOM_SESSION_DURATIONS,
  [SUPPORTED_FEATURES.custom_session_inactivity_timeout]:
    CUSTOM_SESSION_DURATIONS,
  [SUPPORTED_FEATURES.custom_session_token]: CUSTOM_SESSION_DURATIONS,
};

export const PRO_PLAN_ID = 'pro_2023_11';
export const FREE_PLAN_ID = 'free_2022_06';
export const BUSINESS_PLAN_ID = 'business_2022_06';
export const HOBBY_PLAN_ID = 'hobby_2022_06';
