export interface DAPIError {
  code: string;
  message: string;
  long_message?: string;
  meta?: {
    param_name?: string;
    param_names?: string[];
    unsupported_features?: string[];
  };
}

/**
 * Type-guard to ensure the error is an HTTPError
 */
export function isHTTPError(error?: unknown): error is HTTPError {
  return (
    typeof error === 'object' &&
    error !== null &&
    'globalErrors' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  );
}

export class HTTPError extends Error {
  code: number;
  fieldErrors: DAPIError[];
  globalErrors: DAPIError[];
  metaErrors: Array<Record<string, any>>;

  constructor(message: string, code: number, errorData: DAPIError[] = []) {
    super(message);

    this.code = code;
    this.name = 'HTTPError';

    Object.setPrototypeOf(this, HTTPError.prototype);

    const { fieldErrors, globalErrors } = this.parseErrorData(errorData);
    this.fieldErrors = fieldErrors;
    this.globalErrors = globalErrors;
    this.metaErrors = errorData
      .map(error => error?.meta)
      .filter((v): v is NonNullable<DAPIError['meta']> => Boolean(v));
  }

  getFirstGlobalError(): DAPIError | undefined {
    if (this.globalErrors?.length > 0) {
      return this.globalErrors[0];
    }
    return undefined;
  }

  getFieldErrorByName(name: string): DAPIError | undefined {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.fieldErrors.find(err => err.meta!.param_name === name);
  }

  getFieldErrorMessageByName(name: string): string {
    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
    return this.getFieldErrorByName(name)?.message;
  }

  getFieldErrorsMessages(): Record<string, string> {
    return this.fieldErrors.reduce((memo, err) => {
      // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
      const name = err.meta.param_name;
      // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
      memo[name] = err.message;
      return memo;
    }, {});
  }

  getFieldErrorsNames(): string[] {
    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
    return this.fieldErrors.map(err => err.meta.param_name);
  }

  getUnsupportedFeatures(): string[] {
    const metaWithUnsupportedFeatures = this.metaErrors.filter(
      err => err?.unsupported_features,
    );

    return metaWithUnsupportedFeatures.reduce((memo: string[], err) => {
      return [...memo, ...err.unsupported_features];
    }, []) as [];
  }

  private parseErrorData(data: DAPIError[]) {
    return (data || []).reduce<{
      fieldErrors: DAPIError[];
      globalErrors: DAPIError[];
    }>(
      (memo, err) => {
        if (err.meta?.param_name) {
          memo.fieldErrors.push(err);
        } else {
          memo.globalErrors.push(err);
        }

        return memo;
      },
      {
        fieldErrors: [],
        globalErrors: [],
      },
    );
  }
}
