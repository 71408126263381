import type { UserSessionsResponse } from '@/app/api/types/Session';
import type {
  LatestActivityResponse,
  MonthlyMetricsResponse,
  UserActivityResponse,
} from '@/app/api/types/analytics';
import type { ApiKeysResponse } from '@/app/api/types/apiKeys';
import type { DomainStatusResponse } from '@/app/api/types/domains';
import type {
  Instance,
  InstanceKey,
  OrganizationSettings,
  OrganizationSettingsProps,
  WebhookSettings,
} from '@/app/api/types/instances';
import type {
  CreateMembershipParams,
  ListOrganizationMembershipResponse,
} from '@/app/api/types/organizationMemberships';
import type { ListOrganizationResponse } from '@/app/api/types/organizations';
import type { OrganizationInvitationListResponse } from '@/app/api/types/organizationsInvitations';
import type { Template } from '@/app/api/types/templates';
import type {
  Compliance,
  PartialUserSettings,
  UserSettings,
} from '@/app/api/types/userSettings';
import type {
  CreateUserParams,
  EmailAddress,
  ListUsersBffResponse,
  PhoneNumber,
  User,
  UserUpdateParams,
} from '@/app/api/types/users';
import { PAGE_SIZE } from '@/app/constants/table';
import { request } from '@/app/utils/http';
import { ListResponse } from '@/types/utils';
import { queryOptions } from '@tanstack/react-query';
import { AccountPortal } from './types/accountPortal';
import {
  CreateInvitationPayload,
  Invitation,
  ListInvitationsResponse,
} from './types/invitations';
import {
  CreateOAuthApplicationParams,
  OAuthApplication,
  OAuthApplicationWithClientSecret,
  UpdateOAuthApplicationParams,
} from './types/oauthApplications';
import type { ListOrganizationRolesResponse } from './types/organizationRoles';
import type { GetTokenOptions } from './types/utils';
import { ListWaitlistEntriesResponse, WaitlistEntry } from './types/waitlist';

export const getInstance = (
  instanceId: string,
  { getToken }: GetTokenOptions,
) => request<Instance>({ path: `/v1/instances/${instanceId}`, getToken });
export const instanceQuery = (
  instanceId: string,
  { getToken }: GetTokenOptions,
) => {
  return queryOptions({
    queryFn: () => {
      return getInstance(instanceId, { getToken });
    },
    queryKey: ['instance', instanceId],
  });
};

export const getOrganizations = (
  instanceId: string,
  {
    // Page is 1-indexed as is our UI
    page,
    limit = PAGE_SIZE,
    orderBy,
    query,
    getToken,
  }: GetTokenOptions<{
    page: number;
    limit?: number;
    orderBy?: string | null;
    query?: string | null;
  }>,
) => {
  const searchParams = new URLSearchParams();
  searchParams.set('limit', String(limit));
  searchParams.set('offset', String((page - 1) * limit));

  if (orderBy) {
    searchParams.set('order_by', orderBy);
  }
  if (query) {
    searchParams.set('query', query);
  }

  return queryOptions({
    queryFn: () =>
      request<ListOrganizationResponse>({
        path: `/v1/instances/${instanceId}/organizations?${searchParams.toString()}`,
        getToken,
      }),
    queryKey: ['organizations', instanceId, page, limit, orderBy, query],
  });
};

export const getUsers = (
  instanceId: string,
  {
    // Page is 1-indexed as is our UI
    page,
    limit = 10,
    query = '',
    orderBy = '-created_at',
    getToken,
  }: GetTokenOptions<{
    page: number;
    limit?: number;
    query?: string;
    orderBy?: string;
  }>,
) => {
  const searchParams = new URLSearchParams();
  searchParams.set('limit', String(limit));
  searchParams.set('offset', String((page - 1) * limit));
  if (orderBy) {
    searchParams.set('order_by', orderBy);
  }
  if (query) {
    searchParams.set('query', query);
  }

  return request<ListUsersBffResponse>({
    path: `/v1/instances/${instanceId}/bff/users?${searchParams}`,
    getToken,
  });
};

export const usersQuery = (
  instanceId: string,
  {
    page,
    limit = 10,
    query = '',
    orderBy = '-created_at',
    getToken,
  }: GetTokenOptions<{
    page: number;
    limit?: number;
    query?: string;
    orderBy?: string;
  }>,
) => {
  return queryOptions({
    queryFn: () => {
      return getUsers(instanceId, {
        page,
        limit,
        query,
        orderBy,
        getToken,
      });
    },
    queryKey: ['users', instanceId, page, limit, query, orderBy],
  });
};

export const invitationsQuery = (
  instanceId: string,
  {
    page,
    limit = 10,
    query = '',
    orderBy = '-created_at',
    status = 'pending',
    getToken,
  }: GetTokenOptions<{
    page: number;
    limit?: number;
    query?: string;
    orderBy?: string;
    status?: string;
  }>,
) => {
  return queryOptions({
    queryFn: () => {
      const searchParams = new URLSearchParams();
      searchParams.set('limit', String(limit));
      searchParams.set('offset', String((page - 1) * limit));
      searchParams.set('order_by', orderBy);
      searchParams.set('status', status);

      if (query) {
        searchParams.set('query', query);
      }

      if (status === 'all') {
        searchParams.delete('status');
      }

      return request<ListInvitationsResponse>({
        path: `/v1/instances/${instanceId}/invitations?${searchParams}`,
        getToken,
      });
    },
    queryKey: ['invitations', instanceId, page, limit, query, orderBy, status],
  });
};

export const createInvitation = (
  instanceId: string,
  data: CreateInvitationPayload,
  { getToken }: GetTokenOptions,
) =>
  request<Invitation>({
    method: 'POST',
    path: `/v1/instances/${instanceId}/invitations`,
    data,
    getToken,
  });

export const revokeInvitation = (
  instanceId: string,
  invitationId: string,
  { getToken }: GetTokenOptions,
) =>
  request<Invitation>({
    method: 'POST',
    path: `/v1/instances/${instanceId}/invitations/${invitationId}/revoke`,
    getToken,
  });

export const waitlistQuery = (
  instanceId: string,
  {
    page,
    limit = 10,
    query = '',
    orderBy = '-created_at',
    status = 'pending',
    getToken,
  }: GetTokenOptions<{
    page: number;
    limit?: number;
    query?: string;
    orderBy?: string;
    status?: string;
  }>,
) => {
  return queryOptions({
    queryFn: () => {
      const searchParams = new URLSearchParams();
      searchParams.set('limit', String(limit));
      searchParams.set('offset', String((page - 1) * limit));
      searchParams.set('order_by', orderBy);
      searchParams.set('status', status);

      if (query) {
        searchParams.set('query', query);
      }

      if (status === 'all') {
        searchParams.delete('status');
      }

      return request<ListWaitlistEntriesResponse>({
        path: `/v1/instances/${instanceId}/waitlist_entries?${searchParams}`,
        getToken,
      });
    },
    queryKey: [
      'waitlistEntries',
      instanceId,
      page,
      limit,
      query,
      orderBy,
      status,
    ],
  });
};

export const inviteWaitlistEntry = (
  instanceId: string,
  waitlistEntryId: string,
  { getToken }: GetTokenOptions,
) =>
  request<WaitlistEntry>({
    method: 'POST',
    path: `/v1/instances/${instanceId}/waitlist_entries/${waitlistEntryId}/invite`,
    getToken,
    data: {
      ignore_existing: true,
    },
  });

export const denyWaitlistEntry = (
  instanceId: string,
  waitlistEntryId: string,
  { getToken }: GetTokenOptions,
) =>
  request<WaitlistEntry>({
    method: 'POST',
    path: `/v1/instances/${instanceId}/waitlist_entries/${waitlistEntryId}/reject`,
    getToken,
  });

export const bulkInviteWaitlistEntries = (
  instanceId: string,
  { getToken }: GetTokenOptions,
  data: {
    entry_ids: string[];
  },
) =>
  request<never>({
    method: 'POST',
    getToken,
    path: `/v1/instances/${instanceId}/waitlist_entries/bulk_invite`,
    data,
  });

export const bulkRejectWaitlistEntries = (
  instanceId: string,
  { getToken }: GetTokenOptions,
  data: {
    entry_ids: string[];
  },
) =>
  request<never>({
    method: 'POST',
    getToken,
    path: `/v1/instances/${instanceId}/waitlist_entries/bulk_reject`,
    data,
  });

export const organizationSettingsQuery = (
  instanceId: string,
  { getToken }: GetTokenOptions,
) => {
  return queryOptions({
    queryFn: () => {
      return request<OrganizationSettings>({
        path: `/v1/instances/${instanceId}/organization_settings`,
        getToken,
      });
    },
    queryKey: ['organizationSettings', instanceId],
  });
};

export const patchOrganizationSettings = (
  instanceId: string,
  data: Partial<OrganizationSettingsProps>,
  { getToken }: GetTokenOptions,
) =>
  request<OrganizationSettings>({
    data,
    method: 'PATCH',
    path: `/v1/instances/${instanceId}/organization_settings`,
    getToken,
  });

export const organizationRolesQuery = (
  instanceId: string,
  { limit = 50, getToken }: GetTokenOptions<{ limit?: number }>,
) => {
  return queryOptions({
    queryFn: () => {
      return request<ListOrganizationRolesResponse>({
        path: `/v1/instances/${instanceId}/organization_roles?limit=${limit}`,
        getToken,
      });
    },
    queryKey: ['organizationRoles', instanceId],
  });
};

export const specificUserUnaffiliatedOrganizationsQuery = (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
) => {
  return queryOptions({
    queryFn: () => {
      return request<ListOrganizationResponse>({
        path: `/v1/instances/${instanceId}/organizations?query=&user_id=-${userId}&offset=0`,
        getToken,
      });
    },
    queryKey: ['userSpecificUnaffiliatedOrganizations', instanceId, userId],
  });
};

export const userSettingsQuery = (
  instanceId: string,
  { getToken }: GetTokenOptions,
) => {
  return queryOptions({
    queryFn: () => {
      return request<UserSettings>({
        path: `/v1/instances/${instanceId}/user_settings`,
        getToken,
      });
    },
    queryKey: ['userSettings', instanceId],
  });
};

export const patchUserSettings = (
  instanceId: string,
  data: Partial<PartialUserSettings>,
  { getToken }: GetTokenOptions,
) =>
  request<UserSettings>({
    data,
    method: 'PATCH',
    path: `/v1/instances/${instanceId}/user_settings`,
    getToken,
  });

export const complianceQuery = (
  instanceId: string,
  { getToken }: GetTokenOptions,
) => {
  return queryOptions({
    queryFn: () =>
      request<Compliance>({
        path: `/v1/instances/${instanceId}/compliance`,
        getToken,
      }),
    queryKey: ['compliance', instanceId],
  });
};

export const patchCompliance = (
  instanceId: string,
  data: Partial<Compliance>,
  { getToken }: GetTokenOptions,
) =>
  request<Compliance>({
    data,
    method: 'PATCH',
    path: `/v1/instances/${instanceId}/compliance`,
    getToken,
  });

export const smsTemplatesQuery = (
  instanceId: string,
  { getToken }: GetTokenOptions,
) => {
  return queryOptions({
    queryFn: () =>
      request<Template[]>({
        path: `/v1/instances/${instanceId}/templates/sms/all`,
        getToken,
      }),
    queryKey: ['smsTemplates', instanceId],
  });
};

export const createNewOrganizationMembership = (
  instanceId: string,
  organizationId: string,
  data: {
    role: string;
    user_Id: string;
  },
  { getToken }: GetTokenOptions,
) =>
  request<CreateMembershipParams>({
    data,
    method: 'POST',
    path: `/v1/instances/${instanceId}/organizations/${organizationId}/memberships`,
    getToken,
  });

export const postSMSTemplate = (
  instanceId: string,
  data: Partial<Template>,
  { getToken }: GetTokenOptions,
) =>
  request<Template>({
    data,
    method: 'POST',
    path: `/v1/instances/${instanceId}/templates/sms/${data.slug}/${
      data.enabled ? 'enable' : 'disable'
    }`,
    getToken,
  });

export const patchCommunication = (
  instanceId: string,
  data: { blocked_country_codes: string[] },
  { getToken }: GetTokenOptions,
) =>
  request<object>({
    data,
    method: 'PATCH',
    path: `/v1/instances/${instanceId}/communication`,
    getToken,
  });

export const userCountQuery = (
  instanceId: string,
  { getToken }: GetTokenOptions,
  refetchInterval?: number,
) => {
  return queryOptions({
    queryFn: () =>
      request<{ object: string; total_count: number }>({
        method: 'GET',
        path: `/v1/instances/${instanceId}/users/count`,
        getToken,
      }),
    queryKey: ['userCount', instanceId],
    refetchInterval,
  });
};

export const userActivityQuery = (
  instanceId: string,
  activeMetric: string,
  interval: string,
  since: string,
  { getToken }: GetTokenOptions,
) => {
  return queryOptions({
    queryFn: () =>
      request<UserActivityResponse>({
        method: 'GET',
        path: `v1/instances/${instanceId}/analytics/user_activity/${activeMetric}?since=${since}&interval=${interval}`,
        getToken,
      }),
    queryKey: ['userActivity', instanceId, activeMetric, interval, since],
  });
};

export const monthlyMetricsQuery = (
  instanceId: string,
  { getToken }: GetTokenOptions,
  refetchInterval?: number,
) => {
  return queryOptions({
    queryFn: () =>
      request<MonthlyMetricsResponse>({
        method: 'GET',
        path: `v1/instances/${instanceId}/analytics/monthly_metrics`,
        getToken,
      }),
    queryKey: ['monthlyMetrics', instanceId],
    refetchInterval,
  });
};

export const latestActivityQuery = (
  instanceId: string,
  { getToken }: GetTokenOptions,
  refetchInterval?: number,
) => {
  return queryOptions({
    queryFn: () =>
      request<LatestActivityResponse>({
        method: 'GET',
        path: `v1/instances/${instanceId}/analytics/latest_activity`,
        getToken,
      }),
    queryKey: ['latestActivity', instanceId],
    refetchInterval,
  });
};

export const domainStatusQuery = (
  instanceId: string,
  domainId: string,
  { getToken }: GetTokenOptions,
  refetchInterval?: number,
) => {
  return queryOptions({
    queryFn: () =>
      request<DomainStatusResponse>({
        method: 'GET',
        path: `v1/instances/${instanceId}/domains/${domainId}/status`,
        getToken,
      }),
    queryKey: ['domainStatus', instanceId, domainId],
    refetchInterval,
  });
};

export const instanceKeysQuery = (
  instanceId: string,
  { getToken }: GetTokenOptions,
  refetchInterval?: number,
) => {
  return queryOptions({
    queryFn: () =>
      request<InstanceKey[]>({
        method: 'GET',
        path: `v1/instances/${instanceId}/instance_keys`,
        getToken,
      }),
    queryKey: ['instanceKeys', instanceId],
    refetchInterval,
  });
};

export const oauthApplicationsQuery = (
  instanceId: string,
  { getToken }: GetTokenOptions,
  refetchInterval?: number,
) => {
  return queryOptions({
    queryFn: () =>
      request<ListResponse<OAuthApplication>>({
        method: 'GET',
        path: `v1/instances/${instanceId}/oauth_applications`,
        getToken,
      }),
    queryKey: ['oauthApplications', instanceId],
    refetchInterval,
  });
};

export const oauthApplicationQuery = (
  instanceId: string,
  id: string,
  { getToken }: GetTokenOptions,
  refetchInterval?: number,
) => {
  return queryOptions({
    queryFn: () =>
      request<OAuthApplication>({
        method: 'GET',
        path: `v1/instances/${instanceId}/oauth_applications/${id}`,
        getToken,
      }),
    queryKey: ['oauthApplication', instanceId, id],
    refetchInterval,
  });
};

export const createInstanceKey = (
  instanceId: string,
  name: string,
  { getToken }: GetTokenOptions,
) => {
  return request<InstanceKey>({
    method: 'POST',
    path: `v1/instances/${instanceId}/instance_keys`,
    getToken,
    data: { name },
  });
};

export const deleteInstanceKey = (
  instanceId: string,
  id: string,
  { getToken }: GetTokenOptions,
) => {
  return request<InstanceKey>({
    method: 'DELETE',
    path: `v1/instances/${instanceId}/instance_keys/${id}`,
    getToken,
  });
};

export const apiKeysQuery = (
  instanceId: string,
  { getToken }: GetTokenOptions,
) => {
  return queryOptions({
    queryFn: () =>
      request<ApiKeysResponse>({
        method: 'GET',
        path: `v1/instances/${instanceId}/bff/api_keys`,
        getToken,
      }),
    queryKey: ['apiKeys', instanceId],
  });
};

/* Specific user CRUD operations
  ============================================ */

export const createUser = (
  instanceId: string,
  data: CreateUserParams,
  { getToken }: GetTokenOptions,
) =>
  request<User>({
    data,
    method: 'POST',
    path: `/v1/instances/${instanceId}/users`,
    getToken,
  });

export const specificUserSettingsQuery = (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
) => {
  return queryOptions({
    queryFn: () =>
      request<User>({
        path: `/v1/instances/${instanceId}/users/${userId}`,
        getToken,
      }),
    queryKey: ['userSpecificSettings', instanceId, userId],
  });
};

export const patchSpecificUserSettings = (
  instanceId: string,
  userId: string,
  data: UserUpdateParams,
  { getToken }: GetTokenOptions,
) =>
  request<User>({
    data,
    method: 'PATCH',
    path: `/v1/instances/${instanceId}/users/${userId}`,
    getToken,
  });

export const impersonateSpecificUser = (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
) =>
  request<{ location: string }>({
    method: 'POST',
    path: `/v1/instances/${instanceId}/users/${userId}/impersonate`,
    getToken,
  });

export const unlockSpecificUser = (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
) =>
  request<{ location: string }>({
    method: 'POST',
    path: `/v1/instances/${instanceId}/users/${userId}/unlock`,
    getToken,
  });

export const lockSpecificUser = (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
) =>
  request<{ location: string }>({
    method: 'POST',
    path: `/v1/instances/${instanceId}/users/${userId}/lock`,
    getToken,
  });

export const banSpecificUser = (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
) =>
  request<User>({
    method: 'POST',
    path: `/v1/instances/${instanceId}/users/${userId}/ban`,
    getToken,
  });

export const unbanSpecificUser = (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
) =>
  request<User>({
    method: 'POST',
    path: `/v1/instances/${instanceId}/users/${userId}/unban`,
    getToken,
  });

export const deleteSpecificUser = (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
) =>
  request<User>({
    method: 'DELETE',
    path: `/v1/instances/${instanceId}/users/${userId}`,
    getToken,
  });

export const specificUserOrganizationMembershipsQuery = (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
) => {
  return queryOptions({
    queryFn: () => {
      return request<ListOrganizationMembershipResponse>({
        path: `/v1/instances/${instanceId}/users/${userId}/organization_memberships?limit=${8000}`,
        getToken,
      });
    },
    queryKey: ['userSpecificOrganizationMemberships', instanceId, userId],
  });
};

export const deleteSpecificUserOrganizationMembership = (
  instanceId: string,
  userId: string,
  organizationId: string,
  { getToken }: GetTokenOptions,
) =>
  request<never>({
    method: 'DELETE',
    path: `/v1/instances/${instanceId}/organizations/${organizationId}/memberships/${userId}`,
    getToken,
  });

export const postSpecificUserEmail = (
  instanceId: string,
  userId: string,
  data: {
    user_id: string;
    email_address: string;
    primary?: boolean;
    verified?: boolean;
    reserved_for_second_factor?: boolean;
  },
  { getToken }: GetTokenOptions,
) =>
  request<Partial<EmailAddress>>({
    data,
    method: 'POST',
    path: `/v1/instances/${instanceId}/users/${userId}/email_addresses`,
    getToken,
  });

export const patchSpecificUserEmail = (
  instanceId: string,
  userId: string,
  emailAddressId: string,
  data: {
    primary?: boolean | null;
    verified?: boolean;
  },
  { getToken }: GetTokenOptions,
) =>
  request<Partial<EmailAddress>>({
    data,
    method: 'PATCH',
    path: `/instances/${instanceId}/users/${userId}/email_addresses/${emailAddressId}`,
    getToken,
  });

export const deleteSpecificUserEmail = (
  instanceId: string,
  userId: string,
  emailAddressId: string,
  { getToken }: GetTokenOptions,
) =>
  request<never>({
    method: 'DELETE',
    path: `/v1/instances/${instanceId}/users/${userId}/email_addresses/${emailAddressId}`,
    getToken,
  });

export const deleteSpecificUserProfileImage = (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
) =>
  request<never>({
    method: 'DELETE',
    path: `/v1/instances/${instanceId}/users/${userId}/profile_image`,
    getToken,
  });

export const removeSpecificUserPassword = (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
) =>
  request<never>({
    method: 'POST',
    path: `/v1/instances/${instanceId}/users/${userId}/remove_password`,
    getToken,
    /*
     This request is failing without providing a empty body -
     https://github.com/clerk/clerk_go/blob/72fbbf60d17b856f3fc642c8a1225ca7f061bc45/api/dapi/v1/users/http.go#L179-L194
    */
    data: {},
  });

export const deleteSpecificUserPasskey = (
  instanceId: string,
  userId: string,
  passkeyIdentId: string,
  { getToken }: GetTokenOptions,
) =>
  request<never>({
    method: 'DELETE',
    path: `/v1/instances/${instanceId}/users/${userId}/passkeys/${passkeyIdentId}`,
    getToken,
  });

export const postSpecificUserPhoneNumber = (
  instanceId: string,
  userId: string,
  data: {
    user_id: string;
    phone_number: string;
    primary?: boolean;
    verified?: boolean;
    reserved_for_second_factor?: boolean;
  },
  { getToken }: GetTokenOptions,
) =>
  request<Partial<PhoneNumber>>({
    data,
    method: 'POST',
    path: `/v1/instances/${instanceId}/users/${userId}/phone_numbers`,
    getToken,
  });

export const patchSpecificUserPhoneNumber = (
  instanceId: string,
  userId: string,
  phoneNumberId: string,
  data: {
    primary?: boolean | null;
    verified?: boolean;
    reserved_for_second_factor?: boolean;
  },
  { getToken }: GetTokenOptions,
) =>
  request<Partial<PhoneNumber>>({
    data,
    method: 'PATCH',
    path: `/instances/${instanceId}/users/${userId}/phone_numbers/${phoneNumberId}`,
    getToken,
  });

export const deleteSpecificUserPhoneNumber = (
  instanceId: string,
  userId: string,
  phoneNumberId: string,
  { getToken }: GetTokenOptions,
) =>
  request<never>({
    method: 'DELETE',
    path: `/v1/instances/${instanceId}/users/${userId}/phone_numbers/${phoneNumberId}`,
    getToken,
  });

export const deleteUserWeb3Wallet = (
  instanceId: string,
  userId: string,
  web3IdentId: string,
  { getToken }: GetTokenOptions,
) =>
  request<never>({
    method: 'DELETE',
    path: `/v1/instances/${instanceId}/users/${userId}/web3_wallets/${web3IdentId}`,
    getToken,
  });

export const userSessionsQuery = (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
  refetchInterval?: number,
) => {
  return queryOptions({
    queryFn: () =>
      request<UserSessionsResponse>({
        method: 'GET',
        path: `v1/instances/${instanceId}/users/${userId}/sessions?status=active`,
        getToken,
      }),
    queryKey: ['userSessions', instanceId, userId],
    refetchInterval,
  });
};

export const postRevokeUserSessions = (
  instanceId: string,
  userId: string,
  sessionIds: string[],
  { getToken }: GetTokenOptions,
) =>
  request({
    method: 'POST',
    // This call can take a single session or multiple
    path: `/v1/instances/${instanceId}/users/${userId}/sessions/revoke?${sessionIds
      .map((id, index) => `${index === 0 ? '' : '&'}id=${id}`)
      .join('')}`,
    getToken,
  });

export const deleteUserTotp = (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
) =>
  request({
    method: 'DELETE',
    path: `/v1/instances/${instanceId}/users/${userId}/totp`,
    getToken,
  });

export const uploadUserProfileImage = (
  instanceId: string,
  userId: string,
  file: File,
  { getToken }: GetTokenOptions,
) => {
  const formData = new FormData();
  formData.append('file', file);

  return request<User>({
    method: 'POST',
    path: `/v1/instances/${instanceId}/users/${userId}/profile_image`,
    data: formData,
    getToken,
  });
};

export const listOrganizationInvitationsQuery = (
  instanceId: string,
  userId: string,
  { getToken }: GetTokenOptions,
  pagination: { limit?: number; page?: number } = {},
) => {
  return queryOptions({
    queryFn: () => {
      const queryParams = new URLSearchParams();
      if (pagination.limit) {
        queryParams.append('limit', pagination.limit.toString());
      }
      if (pagination.page) {
        queryParams.append(
          'offset',
          ((pagination.page - 1) * (pagination.limit || 10)).toString(),
        );
      }

      return request<OrganizationInvitationListResponse>({
        method: 'GET',
        path: `/v1/instances/${instanceId}/users/${userId}/organization_invitations?${queryParams.toString()}`,
        getToken,
      });
    },
    queryKey: ['organizationInvitations', instanceId, userId, pagination],
  });
};

export const deleteSpecificUserExternalAccount = (
  instanceId: string,
  userId: string,
  externalAccountId: string,
  { getToken }: GetTokenOptions,
) =>
  request<never>({
    method: 'DELETE',
    path: `/v1/instances/${instanceId}/users/${userId}/external_accounts/${externalAccountId}`,
    getToken,
  });

export const revokeOrganizationInvitation = (
  instanceId: string,
  organizationId: string,
  orgInvitationId: string,
  { getToken }: GetTokenOptions,
) =>
  request<never>({
    method: 'POST',
    path: `/v1/instances/${instanceId}/organizations/${organizationId}/invitations/${orgInvitationId}/revoke`,
    getToken,
    /*
     This request is failing without providing a empty body -
     https://github.com/clerk/clerk_go/blob/948bdbcb4ae29af412b6ef5331be95dcda9a7234/api/dapi/v1/organizations/http.go#L261C1-L273C2
    */
    data: {},
  });

export const createOrganizationInvitation = (
  instanceId: string,
  organizationId: string,
  data: {
    email_address: string;
    role: string;
  },
  { getToken }: GetTokenOptions,
) =>
  request({
    data,
    method: 'POST',
    path: `/v1/instances/${instanceId}/organizations/${organizationId}/invitations`,
    getToken,
  });

export const webhookSettingsQuery = (
  instanceId: string,
  { getToken }: GetTokenOptions,
) => {
  return queryOptions({
    queryFn: async () => {
      return request<WebhookSettings>({
        path: `/v1/instances/${instanceId}/webhooks/svix`,
        getToken,
      });
    },
    queryKey: ['webhookSettings', instanceId],
  });
};

export const createSvixIntegration = async (
  instanceId: string,
  { getToken }: GetTokenOptions,
) =>
  await request({
    method: 'POST',
    path: `/v1/instances/${instanceId}/webhooks/svix`,
    getToken,
  });

export const createOAuthApplication = (
  instanceId: string,
  data: CreateOAuthApplicationParams,
  { getToken }: GetTokenOptions,
) =>
  request<OAuthApplicationWithClientSecret>({
    data,
    method: 'POST',
    path: `/v1/instances/${instanceId}/oauth_applications`,
    getToken,
  });

export const deleteOAuthApplication = (
  instanceId: string,
  id: string,
  { getToken }: GetTokenOptions,
) =>
  request<{ id: string; slug: string; deleted: boolean }>({
    method: 'DELETE',
    path: `/v1/instances/${instanceId}/oauth_applications/${id}`,
    getToken,
  });

export const rotateOAuthApplicationClientSecret = (
  instanceId: string,
  id: string,
  { getToken }: GetTokenOptions,
) =>
  request<OAuthApplicationWithClientSecret>({
    method: 'POST',
    path: `/v1/instances/${instanceId}/oauth_applications/${id}/rotate_secret`,
    getToken,
  });

export const updateOauthApplication = (
  instanceId: string,
  id: string,
  data: UpdateOAuthApplicationParams,
  { getToken }: GetTokenOptions,
) =>
  request<OAuthApplicationWithClientSecret>({
    data,
    method: 'PATCH',
    path: `/v1/instances/${instanceId}/oauth_applications/${id}`,
    getToken,
  });

export const accountPortalQuery = (
  instanceId: string,
  { getToken }: GetTokenOptions,
  refetchInterval?: number,
) => {
  return queryOptions({
    queryFn: () =>
      request<AccountPortal>({
        method: 'GET',
        path: `v1/instances/${instanceId}/account_portal`,
        getToken,
      }),
    queryKey: ['accountPortal', instanceId],
    refetchInterval,
  });
};
