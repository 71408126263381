import React from 'react';

const DocumentationIcon = () => {
  return (
    <svg
      width='33'
      height='33'
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M30.0607 6.42V24.66C30.0607 25.7204 29.2011 26.58 28.1407 26.58H24.0817C22.6372 26.58 21.202 26.8129 19.8316 27.2697L16.1407 28.5L12.4498 27.2697C11.0794 26.8129 9.64425 26.58 8.19969 26.58H4.1407C3.08032 26.58 2.2207 25.7204 2.2207 24.66V6.42C2.2207 5.35961 3.08032 4.5 4.1407 4.5H4.2367C8.29254 4.5 12.3082 5.30314 16.0521 6.86308L16.1407 6.9L16.2293 6.86308C19.9732 5.30314 23.9889 4.5 28.0447 4.5H28.1407C29.2011 4.5 30.0607 5.35961 30.0607 6.42Z'
        className='fill-purple-400'
      />
      <path
        d='M16.1407 6.9V28.5M16.1407 6.9L16.0521 6.86308C12.3082 5.30314 8.29254 4.5 4.2367 4.5H4.1407C3.08032 4.5 2.2207 5.35961 2.2207 6.42V24.66C2.2207 25.7204 3.08032 26.58 4.1407 26.58H8.19969C9.64425 26.58 11.0794 26.8129 12.4498 27.2697L16.1407 28.5M16.1407 6.9L16.2293 6.86308C19.9732 5.30314 23.9889 4.5 28.0447 4.5H28.1407C29.2011 4.5 30.0607 5.35961 30.0607 6.42V24.66C30.0607 25.7204 29.2011 26.58 28.1407 26.58H24.0817C22.6372 26.58 21.202 26.8129 19.8316 27.2697L16.1407 28.5'
        className='stroke-purple'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default DocumentationIcon;
