'use client';

import {
  UserButton as ClerkUserButton,
  SignedIn,
  useSession,
} from '@clerk/nextjs';
import { Skeleton } from './ui/Skeleton';

export function UserButton() {
  const { isLoaded } = useSession();
  return (
    <div className='size-7'>
      {isLoaded ? (
        <SignedIn>
          <ClerkUserButton
            // This is not currently used because of a bug in the SDK
            afterSwitchSessionUrl='/'
            // Instead this is used, it exists but is not typed, we should
            // remove this when SDKI-784 is fixed
            // @ts-expect-error
            afterSignInUrl='/'
            appearance={{
              elements: {
                UserButtonPopoverCard:
                  '-translate-y-[0.1875rem] rounded-[0.875rem] [animation:none] bg-clip-padding border border-black/5 bg-white dark:bg-gray-400 shadow-lg dark:shadow-black/20',
                userButtonTrigger:
                  'after:absolute after:-inset-1 after:rounded-full after:border-2 after:border-blue-900 after:opacity-0 focus-visible:after:opacity-100 focus:shadow-none',
              },
            }}
          />
        </SignedIn>
      ) : (
        <Skeleton width={28} height={28} rounded='full' />
      )}
    </div>
  );
}
