import { IconProps } from '@/app/components/Icon';

export function UnplugIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M11.7262 13.9877L6.01233 8.27382C4.29334 9.9928 3.1355 12.0466 5.56439 14.4754C7.99328 16.9043 10.0022 15.7116 11.7262 13.9877Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path
            d='M9.65477 4.63296L15.3671 10.3452C17.0856 8.62673 18.2928 6.62331 15.8149 4.14533C13.3369 1.66735 11.3783 2.90947 9.65477 4.63296Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M5.56439 14.4754L2.75 17.25M5.56439 14.4754C7.99328 16.9043 10.0022 15.7116 11.7262 13.9877L6.01233 8.27382C4.29334 9.9928 3.1355 12.0466 5.56439 14.4754ZM15.8149 4.14533L17.2479 2.7521M15.8149 4.14533C13.3369 1.66735 11.3783 2.90947 9.65477 4.63296L15.3671 10.3452C17.0856 8.62673 18.2928 6.62331 15.8149 4.14533ZM7.4991 9.76058L8.98587 8.27382M10.261 12.5225L11.7262 11.0573' />
        </>
      ) : (
        <>
          <path d='M4.78801 11.2408L2.75 13.25M4.78801 11.2408C6.54685 12.9997 8.00162 12.136 9.25 10.8876L5.11237 6.75C3.86759 7.99478 3.02916 9.48198 4.78801 11.2408Z' />
          <path d='M12.2108 3.76041L13.2485 2.75152M12.2108 3.76041C10.4164 1.96601 8.99804 2.86548 7.75 4.11352L11.8865 8.25C13.1309 7.00556 14.0052 5.55481 12.2108 3.76041Z' />
          <path d='M6.5 7.5L6.99219 7.02344' />
          <path d='M8.5 9.5L8.99219 9.02344' />
        </>
      )}
    </>
  );
}
