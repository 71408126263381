import React, { useCallback } from 'react';
import { Icon } from '@clerk/ceramic/experimental/components/Icon';
import { useRouter } from 'next/router';
import { useCheckout } from '@components/checkout/checkoutContext';
import {
  useOrganizationCheckoutPortalSession,
  useOrganizationCheckoutSubscription,
} from '@dapi/currentOrganization/mutations';
import { useOrganization, useClerk } from '@clerk/nextjs';
import { cx } from 'cva';
import { MEMBERSHIPS_UNLIMITED_PLAN_ID } from './constants';
import { useOrganizationSubscriptionPlans } from '@hooks/useOrganizationSubscriptionPlans';
import { useSystemConfig } from '@dapi/system/queries';
import { buildCurrentOrganizationPath } from '@dapi/currentOrganization/utils';

export const UpgradeMembershipLimit = () => {
  const router = useRouter();
  const { setCheckout, setOptions } = useCheckout();
  const { organization } = useOrganization();
  const { data: { feature_flags } = {} } = useSystemConfig();
  const { data: plans } = useOrganizationSubscriptionPlans();
  const clerk = useClerk();

  const planId = plans?.find(p => p.action === 'upgrade')?.id;

  const returnUrl = `${window.location.origin}/checkout/completed`;

  const {
    trigger: createCheckoutPortalSession,
    isMutating: isCheckoutPortalSessionMutating,
  } = useOrganizationCheckoutPortalSession({
    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
    planId: planId,
    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
    id: organization?.id,
  });

  const { trigger: checkoutSubscription, isMutating: isCheckoutMutating } =
    // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
    useOrganizationCheckoutSubscription({ id: organization?.id });

  const isMutating = isCheckoutMutating || isCheckoutPortalSessionMutating;

  const clearCheckout = useCallback(() => {
    void router.replace(router.asPath);
  }, []);

  const onHandleCheckoutSubscription = async () => {
    if (isMutating) {
      return;
    }

    if (feature_flags?.allow_new_pricing_checkout) {
      const response = await checkoutSubscription({
        plans: [MEMBERSHIPS_UNLIMITED_PLAN_ID],
      });

      clerk.closeOrganizationProfile();

      setOptions({
        afterCheckoutCompletedUrl: returnUrl,
        onClearCheckout: clearCheckout,
      });

      const stripeSessionIdStatusEndpoint = buildCurrentOrganizationPath({
        resourceId: organization?.id,
        action: 'checkout_subscription/details',
      });

      setCheckout({ ...response, stripeSessionIdStatusEndpoint });
      void router.push('/checkout');
    } else {
      const res = await createCheckoutPortalSession({ returnUrl });

      if (res && res?.url) {
        window.history.replaceState({}, document.title, window.location.origin);
        await router.push(res?.url);
      }
    }
  };

  return (
    <span className='text-legacyGray-700'>
      You can invite up to 3 members to access the app.{' '}
      <button
        disabled={isMutating}
        onClick={onHandleCheckoutSubscription}
        className={cx(
          'inline-flex items-center font-medium disabled:cursor-not-allowed disabled:opacity-50',
          {
            'hover:cursor-default': isMutating,
            'hover:cursor-pointer': !isMutating,
            'text-legacyGray-400': isMutating,
            'text-legacyGray-950': !isMutating,
          },
        )}
      >
        Upgrade to unlimited members&nbsp;
        <Icon name='arrow-right-legacy' size='sm' />
      </button>
    </span>
  );
};
