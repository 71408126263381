import { cx } from 'cva';

export function Label(props: {
  htmlFor: string;
  label: React.ReactNode;
  labelDetail?: React.ReactNode;
  disabled?: boolean;
  padded?: boolean;
  optional?: boolean;
}) {
  const padded = props.padded ?? false;

  return (
    <div
      className={cx('flex-1', {
        '[--label-padding:theme(spacing.3)]': padded,
      })}
    >
      <div className='flex items-center justify-between'>
        <label
          className={cx(
            'flex select-none items-center gap-2 pl-[--label-padding] font-medium',
            {
              'text-gray-1000': props.disabled,
            },
          )}
          htmlFor={props.htmlFor}
        >
          {props.label}
        </label>
        {props.optional ? (
          <span className='rounded-sm border border-gray-400 bg-gray-300 px-1 py-0.5 text-xs font-medium text-gray-1000'>
            Optional
          </span>
        ) : null}
      </div>

      {props.labelDetail && (
        <div className='mt-1 pl-[--label-padding] text-sm text-gray-1100'>
          {props.labelDetail}
        </div>
      )}
    </div>
  );
}
