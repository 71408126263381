'use client';

// !TODO footer conflicts with Ghost style

import { Label } from '@/app/components/ui/Label';
import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import { cx } from 'cva';

interface RadioGroupItem {
  label: React.ReactNode;
  value: string;
  description?: string;
  children?: React.ReactNode;
  disabled?: boolean;
}

interface RadioGroupProps {
  value?: string;
  onValueChange?: (value: string) => void;
  className?: string;
  items: RadioGroupItem[];
  nested?: boolean;
  name?: string;
  disabled?: boolean;
  required?: boolean;
  row?: boolean;
}

export function RadioGroup({
  className,
  items,
  nested,
  value,
  onValueChange,
  name,
  disabled = false,
  row = false,
}: RadioGroupProps) {
  return (
    <RadixRadioGroup.Root
      name={name}
      disabled={disabled}
      className={cx(
        className,
        nested ? 'border-l border-gray-400 px-6 dark:border-gray-600' : '',
        {
          'flex items-center gap-3': row,
          'opacity-50': disabled,
          'space-y-4': !row,
        },
      )}
      aria-label='View density'
      value={value}
      onValueChange={onValueChange}
    >
      {items.map(item => {
        const value = item.value;

        return (
          <div key={value} className='flex gap-3'>
            <RadixRadioGroup.Item
              className={cx(
                'relative mt-0.5 inline-flex size-4 shrink-0 items-center justify-center rounded-full border border-black/10 bg-white shadow-sm shadow-black/6 outline-none dark:border-black/10 dark:bg-gray-600 dark:shadow-black/10',
                'data-[state=checked]:border-gray-1200 data-[state=checked]:bg-gray-1200 data-[state=checked]:shadow-black/20 dark:data-[state=checked]:bg-gray-1200',
                'before:invisible before:absolute before:inset-0 before:rounded-inherit before:shadow-[inset_0_0_0_0.5px] before:shadow-white/8 dark:before:visible',
                // Focus
                'ring-blue-900 ring-offset-2 focus-visible:ring-2',
              )}
              value={value}
              id={value}
              disabled={item.disabled}
            >
              <RadixRadioGroup.Indicator className='size-1.5 rounded-full bg-gray-100' />
            </RadixRadioGroup.Item>
            <div className='flex grow flex-col gap-2'>
              <Label
                htmlFor={value}
                label={item.label}
                labelDetail={item.description}
                disabled={item.disabled}
              />
              {item.children}
            </div>
          </div>
        );
      })}
    </RadixRadioGroup.Root>
  );
}
