export function pluralize(
  value: number | string,
  noun: string,
  showValue: boolean = true,
  suffix = 's',
): string {
  return showValue
    ? `${value} ${noun}${Number(value) !== 1 ? suffix : ''}`
    : `${noun}${Number(value) !== 1 ? suffix : ''}`;
}
