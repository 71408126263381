// Moved these permissions / supported features to app router directory to maintain one source of truth.
export {
  PERMISSIONS,
  SUPPORTED_FEATURES,
  SUPPORTED_FEATURES_TITLES,
} from '../app/constants/permissions';

export type {
  SupportedFeaturesKeys,
  SupportedFeaturesValues,
} from '../app/constants/permissions';

export {
  JS_FRAMEWORKS,
  START_BUILDING_FRAMEWORKS,
} from '@/app/constants/frameworks';

export {
  DEFAULT_ANIMATION_DURATION,
  DEFAULT_ANIMATION_TYPE,
  RADIO_GROUP_ANIMATION_DURATION,
} from './animations';

export { STATIC_LAUNCH_DATES } from './static-launch-dates';

// For full-text search using trigram indexes, minimum search input length must be 3
export const MIN_SEARCH_LENGTH = 3;
