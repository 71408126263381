import * as RadixDialog from '@radix-ui/react-dialog';
import { useControllableState } from '../../hooks/useControllableState';
import { easeOut } from '../utils/easings';
import { AnimatePresence, motion } from 'motion/react';
import { Button } from './Button';
import { cx } from 'cva';
import { Icon, type IconName } from './Icon';

type DialogHeaderProps = {
  title: string | React.ReactNode;
  description?: React.ReactNode;
};

export function Header({ title, description }: DialogHeaderProps) {
  return (
    <div className='relative flex items-start gap-6 px-5 py-4'>
      <div className='mt-1 flex w-full flex-col gap-1'>
        <RadixDialog.Title className='text-xl font-medium text-primary'>
          {title}
        </RadixDialog.Title>
        <RadixDialog.Description className='max-w-[32ch] text-secondary'>
          {description}
        </RadixDialog.Description>
      </div>

      <div>
        <RadixDialog.Close asChild>
          <Button icon='close-legacy' shape='square' intent='ghost'>
            Close
          </Button>
        </RadixDialog.Close>
      </div>
    </div>
  );
}

type DialogContentProps = {
  children: React.ReactNode;
  transparent?: boolean;
  className?: string;
};

export function Content({
  children,
  transparent,
  className,
}: DialogContentProps) {
  return (
    <div
      className={cx(
        {
          'group/dialog-content overflow-hidden rounded-lg bg-white shadow shadow-black/[.06] ring-1 ring-black/[0.06]':
            !transparent,
        },
        className,
      )}
    >
      {children}
    </div>
  );
}

export function Section({ children }: { children: React.ReactNode }) {
  return (
    <section data-id='section' className='space-y-5 px-5 pb-6 pt-4.5'>
      {children}
    </section>
  );
}

export interface NoticeProps {
  children: React.ReactNode;
  icon?: IconName;
  intent?: 'neutral' | 'warning' | 'danger';
  className?: string;
}

export function Notice({
  children,
  icon = 'information-square-legacy',
  intent = 'warning',
  className,
}: NoticeProps) {
  return (
    <section
      data-id='notice'
      className={cx(
        'block bg-gradient-to-b to-transparent',
        'group-has-[section+section]/dialog-content:border-t',
        {
          'border-legacy-orange-100 from-legacy-orange-50':
            intent === 'warning',
          'border-legacy-red-100 from-legacy-red-50': intent === 'danger',
          'border-legacyGray-100 from-legacyGray-50': intent === 'neutral',
        },
        className,
      )}
    >
      <div
        className={cx(
          'flex gap-1.5 px-5 py-3 text-[0.8125rem] font-normal leading-[1.125rem]',
          {
            'text-legacy-orange-600 [&>svg]:text-legacy-orange-500':
              intent === 'warning',
            'text-legacy-red-500 [&>svg]:text-legacy-red-400':
              intent === 'danger',
            'text-legacyGray-500 [&>svg]:text-legacyGray-400':
              intent === 'neutral',
          },
        )}
      >
        <Icon name={icon} size='sm' />
        <div>{children}</div>
      </div>
    </section>
  );
}

type DialogFooterProps = {
  children: React.ReactNode;
};

export function Footer({ children }: DialogFooterProps) {
  return (
    <div className='flex items-center justify-end gap-3 px-5 py-4'>
      {children}
    </div>
  );
}

type DialogCloseProps = {
  children: React.ReactNode;
};

export function Close({ children }: DialogCloseProps) {
  return <RadixDialog.Close asChild>{children}</RadixDialog.Close>;
}

export type DialogProps = {
  trigger?: React.ReactNode;
  children: React.ReactNode;
  defaultOpen?: boolean;
  open?: boolean;
  onOpenChange?: (boolean: boolean) => void;
  fullWidth?: boolean;
  transparent?: boolean;
};

export function Root({
  trigger,
  children,
  defaultOpen,
  open: openProp,
  onOpenChange,
  fullWidth,
  transparent,
}: DialogProps) {
  const [open = false, setOpen] = useControllableState({
    prop: openProp,
    defaultProp: defaultOpen,
    onChange: onOpenChange,
  });

  return (
    <RadixDialog.Root
      defaultOpen={defaultOpen}
      open={open}
      onOpenChange={setOpen}
    >
      {trigger && <RadixDialog.Trigger asChild>{trigger}</RadixDialog.Trigger>}
      <AnimatePresence>
        {open && (
          <RadixDialog.Portal forceMount>
            <RadixDialog.Overlay asChild>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, transition: { duration: 0.25 } }}
                transition={{
                  ease: easeOut,
                  duration: 0.3,
                }}
                className='fixed inset-0 grid items-start justify-center overflow-y-auto bg-black/40 py-40 backdrop-blur'
              >
                <RadixDialog.Content asChild>
                  <motion.div
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{
                      opacity: 0,
                      scale: 0.98,
                      transition: { duration: 0.15 },
                    }}
                    transition={{ ease: easeOut, duration: 0.5 }}
                    className={cx(
                      !fullWidth && 'w-[94vw] max-w-[440px]',
                      !transparent &&
                        'rounded-xl bg-legacyGray-50 shadow-lg ring-1 ring-black/[0.06]',
                      'focus:outline-none',
                    )}
                  >
                    {children}
                  </motion.div>
                </RadixDialog.Content>
              </motion.div>
            </RadixDialog.Overlay>
          </RadixDialog.Portal>
        )}
      </AnimatePresence>
    </RadixDialog.Root>
  );
}
