import { useParams } from 'next/navigation';

export function useTypedParams<T>() {
  const params = useParams<
    {
      applicationId: string;
      instanceId: string;
    } & T
  >()!;

  if (!params.applicationId || !params.instanceId) {
    throw new Error(
      'This hook should only be used within `/apps/:applicationId/instances/:instanceId/*` pages',
    );
  }

  return params;
}
