import { toast } from '@clerk/ceramic/components/Toast';
import { useRouter } from 'next/router';
import { useInstance } from '@/hooks/useInstance';

export const useExportUsersToast = () => {
  const { data: instance } = useInstance();
  const router = useRouter();

  const showGoToExportsButton = (applicationId, instanceId) => {
    return (
      // Show 'Go to exports' button if the user is not on the exports page
      // or is on the exports page of another app
      !window.location.pathname.endsWith('/settings') ||
      instance?.application_id !== applicationId ||
      instance?.id !== instanceId
    );
  };

  const showExportUsersToast = (instanceId, applicationId, applicationName) => {
    const exportUsersToast = toast.success(
      `The user list for ${applicationName} is now available for download.`,
      {
        duration: Infinity,
        action: showGoToExportsButton(applicationId, instanceId)
          ? {
              label: 'Go to settings',
              onClick: async () => {
                await router.push(
                  `/apps/${applicationId}/instances/${instanceId}/settings`,
                );
                toast.dismiss(exportUsersToast);
              },
            }
          : undefined,
        mutedAction: {
          label: 'Dismiss',
        },
      },
    );
  };

  return { showExportUsersToast };
};
