import * as Popover from '@radix-ui/react-popover';
import { Icon } from '@clerk/ceramic/experimental/components/Icon';
import copy from 'clipboard-copy';
import { forwardRef, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'motion/react';
import { VariantProps, cva } from 'cva';

const trigger = cva({
  base: [
    'grid flex-none place-content-center outline-none transition-colors hover:text-primary aria-expanded:text-primary initial:text-secondary',
    'focus-visible:text-primary focus-visible:ring-black/10 dark:focus-visible:ring-white/20',
  ],
  variants: {
    intent: {
      iconButton: [
        'rounded-sm',
        'hover:bg-legacyGray-100 dark:hover:bg-transparent',
      ],
      textButton: [
        'relative rounded-md border border-black/10 font-medium',
        'shadow-[0_1px_0_theme(colors.black/0.02),0_2px_3px_-1px_theme(colors.black/0.06)]',
        'dark:border-white/10 dark:shadow-[0_1px_0,0_2px_3px_-1px] dark:shadow-white/2',
        'before:pointer-events-none before:absolute before:inset-0 before:rounded-md before:bg-gradient-to-b before:from-transparent before:to-legacyGray-950 before:opacity-2',
        'hover:bg-legacyGray-100 dark:hover:bg-transparent',
      ],
      codeBlock: 'rounded-sm',
    },
    size: {
      default: 'size-4 focus-visible:ring-[0.09375rem]',
      md: 'size-6 focus-visible:ring-[0.1875rem]',
      full: 'px-1 py-0.5 text-xs focus-visible:ring-1',
    },
  },
  defaultVariants: {
    size: 'default',
    intent: 'iconButton',
  },
});

type CopyButtonRef = HTMLButtonElement;
interface CopyButtonProps extends VariantProps<typeof trigger> {
  // If buttonText is provided the icon will not be present.
  buttonText?: string;
  /**
   * Aria label for the button. Describes the action that will be performed when the button is clicked.
   */
  ariaLabel: string;
  /**
   * The text to be copied to the clipboard.
   */
  text: string;
  /**
   * Function to be called after the text is copied to the clipboard.
   */
  onCopied?: () => void;
}

export const CopyButton = forwardRef<CopyButtonRef, CopyButtonProps>(
  function CopyButton(props, forwardedRef) {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      let timer;
      if (isVisible) {
        timer = setTimeout(() => {
          setIsVisible(false);
        }, 1500);
      }
      return () => {
        clearTimeout(timer);
      };
    }, [isVisible]);

    async function handleClick(text) {
      setIsVisible(prevIsVisible => !prevIsVisible);
      await copy(text);
      props.onCopied?.();
    }

    return (
      <Popover.Root open={isVisible}>
        <Popover.Trigger
          ref={forwardedRef}
          aria-label={props.ariaLabel}
          onClick={() => handleClick(props.text)}
          className={trigger({ size: props.size, intent: props.intent })}
        >
          {props.buttonText ? (
            <span>{props.buttonText}</span>
          ) : (
            <Icon name='clipboard' fill='transparent' size='sm' />
          )}
        </Popover.Trigger>

        <AnimatePresence>
          {isVisible && (
            <Popover.Content forceMount side='top' asChild>
              <motion.div
                className='flex items-center gap-0.5 rounded-sm bg-legacyGray-900 py-0.5 pl-1.5 pr-1 text-2xs font-medium text-white shadow-lg outline-none'
                initial={{ opacity: 0, y: 2, scale: 0.98 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{ opacity: 0, y: 2, scale: 0.98 }}
              >
                Copied
                <Icon name='check-legacy' size='sm' />
              </motion.div>
            </Popover.Content>
          )}
        </AnimatePresence>
      </Popover.Root>
    );
  },
);
