'use client';

import { useTypedParams } from '@/app/hooks/useTypedParams';
import { findApplication, findInstance } from '@/app/utils/url';
import { useAuth } from '@clerk/nextjs';
import { useSuspenseQuery } from '@tanstack/react-query';
import { applicationsQuery } from './applications';

export const useCurrentApplication = () => {
  const { sessionId, orgId, getToken } = useAuth();
  const { applicationId } = useTypedParams();

  const { data } = useSuspenseQuery({
    ...applicationsQuery(sessionId!, orgId, { getToken }),
    select: applications => {
      return findApplication(applications, applicationId);
    },
  });

  if (!data) {
    // TODO: We might want to make this a "RedirectToHomeError" and add some
    // logic in error.tsx to redirect
    throw new Error('Current application not found');
  }

  return data;
};

export const useCurrentInstance = () => {
  const { sessionId, orgId, getToken } = useAuth();
  const { applicationId, instanceId } = useTypedParams();

  const { data } = useSuspenseQuery({
    ...applicationsQuery(sessionId!, orgId, { getToken }),
    select: applications => {
      const application = findApplication(applications, applicationId);
      return application ? findInstance(application, instanceId) : undefined;
    },
  });

  if (!data) {
    // TODO: We might want to make this a "RedirectToHomeError" and add some
    // logic in error.tsx to redirect
    throw new Error('Current instance not found');
  }

  return data;
};
