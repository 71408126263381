import { IconProps } from '@/app/components/Icon';

export function OauthApplicationsIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z'
            fill='currentColor'
            fillOpacity={0.15}
            stroke='none'
          />
          <path d='M7.75 11.75V10C7.75 8.75736 8.75736 7.75 10 7.75V7.75C11.2426 7.75 12.25 8.75736 12.25 10V11.75M7.75 11.75H6.25V16.25C6.25 16.8023 6.69772 17.25 7.25 17.25H12.75C13.3023 17.25 13.75 16.8023 13.75 16.25V11.75H7.75Z' />
          <path
            d='M3.39786 13C2.98178 12.0858 2.75 11.07 2.75 10C2.75 5.99594 5.99594 2.75 10 2.75C14.0041 2.75 17.25 5.99594 17.25 10C17.25 11.07 17.0182 12.0858 16 15'
            strokeDasharray='0.25 3'
          />
        </>
      ) : (
        <>
          <path d='M7.3117 2.79471C7.53695 2.76522 7.7667 2.75 8 2.75C8.2333 2.75 8.46304 2.76522 8.6883 2.79471M4.08681 4.5C4.3585 4.19645 4.66499 3.92469 5.00001 3.69101M11 3.69101C11.335 3.92469 11.6415 4.19645 11.9132 4.5M13.0326 6.5C13.1283 6.82151 13.194 7.15592 13.2265 7.5M2.7735 7.5C2.80601 7.15592 2.87172 6.82151 2.96741 6.5' />
          <path d='M6.25 8.75V7.5C6.25 6.5335 7.0335 5.75 8 5.75V5.75C8.9665 5.75 9.75 6.5335 9.75 7.5V8.75M6.25 8.75H4.75V12.25C4.75 12.8023 5.19772 13.25 5.75 13.25H10.25C10.8023 13.25 11.25 12.8023 11.25 12.25V8.75H6.25Z' />
        </>
      )}
    </>
  );
}
