import { Icon } from '@clerk/ceramic/experimental/components/Icon';
import { easeOut } from '@clerk/ceramic/experimental/utils/easings';
import { cx } from 'cva';
import { AnimatePresence, MotionConfig, motion } from 'motion/react';
import { useStaffMode } from './StaffMode';
import { useTheme } from 'next-themes';

export default function ColorSchemeToggle() {
  const { staffModeEnabled: isColorSchemeEnabled } = useStaffMode({
    feature: 'color-scheme-toggle',
  });

  const { theme, setTheme } = useTheme();

  if (!isColorSchemeEnabled) {
    return null;
  }

  return (
    <button
      className={cx(
        'group flex w-full items-center gap-3 rounded py-1.5 outline-none [transform-style:preserve-3d]',
        'focus-visible:ring-[0.1875rem] focus-visible:ring-black/8',
      )}
      onClick={() => (theme === 'dark' ? setTheme('light') : setTheme('dark'))}
    >
      <span
        className={cx(
          'transition-[color,transform]',
          'duration-200',
          theme === 'dark' ? 'rotate-[360deg]' : 'rotate-0',
        )}
      >
        <Icon name='color-scheme-legacy' size='sm' />
      </span>
      <span className='flex items-center gap-1.5 [perspective:500px]'>
        <span
          className={cx(
            'truncate font-book capitalize leading-none transition group-hover:text-primary initial:text-secondary',
          )}
        >
          Color Scheme
        </span>

        <MotionConfig transition={{ duration: 0.4, ease: easeOut }}>
          <AnimatePresence mode='popLayout' initial={false}>
            {theme === 'dark' ? (
              <motion.span
                key={'staff-mode-enabled-badge'}
                initial={{
                  y: -8,
                  opacity: 0,
                  rotateX: 90,
                  filter: 'blur(2px)',
                }}
                animate={{ y: 0, opacity: 1, rotateX: 0, filter: 'blur(0px)' }}
                exit={{ y: 8, opacity: 0, rotateX: -90, filter: 'blur(2px)' }}
                className='inline-flex rounded bg-legacyGray-800 px-1.5 py-xs text-xs font-medium text-white shadow-[inset_0_1px_0_theme(colors.white/0.04),inset_0_0_0_1px_theme(colors.white/0.02),0_0_0_1px_theme(colors.black/0.2),0_2px_2px_-1px_theme(colors.black/0.2)]'
              >
                Dark
              </motion.span>
            ) : (
              <motion.span
                key={'staff-mode-disabled-badge'}
                initial={{
                  y: -8,
                  opacity: 0,
                  rotateX: 90,
                  filter: 'blur(2px)',
                }}
                animate={{ y: 0, opacity: 1, rotateX: 0, filter: 'blur(0px)' }}
                exit={{ y: 8, opacity: 0, rotateX: -90, filter: 'blur(2px)' }}
                className={cx(
                  'inline-flex rounded px-1.5 py-xs text-xs font-medium text-secondary',
                  'bg-white shadow-[0px_2px_3px_-1px_rgba(0,_0,_0,_0.04),_0px_1px_0px_0px_rgba(25,_28,_33,_0.01),_0px_0px_0px_1px_rgba(25,_28,_33,_0.08)]',
                )}
              >
                Light
              </motion.span>
            )}
          </AnimatePresence>
        </MotionConfig>
      </span>
    </button>
  );
}
