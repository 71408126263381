'use client';

import { Instance, InstanceFeaturePlan } from '@/app/api/types/instances';
import { Icon } from '@/app/components/Icon';
import { Badge } from '@/app/components/badge';
import { Button } from '@/app/components/ui/Button';
import { to } from '@/app/utils/url';
import { SUPPORTED_FEATURES_TITLES } from '@constants';
import * as Popover from '@radix-ui/react-popover';
import { AnimatePresence, motion } from 'motion/react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useState } from 'react';

type Props = { unsupportedFeatures: string[]; features: Instance['features'] };
export const UnsupportedFeatures = ({
  unsupportedFeatures,
  features,
}: Props) => {
  const pathname = usePathname()!;

  const [open, setOpen] = useState(false);

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      This application is using{' '}
      <Popover.Trigger className='font-medium outline-none'>
        <span className='underline'>features unsupported</span>
      </Popover.Trigger>{' '}
      by the current plan.
      <Popover.Portal forceMount>
        <Popover.Content sideOffset={3} className='outline-none'>
          <AnimatePresence>
            {open && (
              <motion.div
                initial={{ opacity: 0, y: -4 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{
                  opacity: 0,
                  y: -4,
                  transition: { duration: 0.15 },
                }}
                transition={{
                  type: 'spring',
                  stiffness: 500,
                  damping: 28,
                  opacity: { duration: 0.2 },
                }}
                className='w-96 overflow-hidden rounded-xl border border-black/[0.06] bg-surface-100 bg-clip-padding shadow-lg'
              >
                <div className='rounded-b-lg border-b border-legacyGray-200/75 bg-surface-200 shadow-[0_1px_2px_theme(colors.black/0.04)]'>
                  <header className='space-y-1 border-b border-primary px-4 py-3'>
                    <p className='font-medium'>Unsupported features</p>
                    <p className='text-sm text-secondary'>
                      List of active unsupported features under your current
                      plan
                    </p>
                  </header>

                  <ul className='divide-y divide-legacyGray-100'>
                    {unsupportedFeatures.map(feature => (
                      <li
                        className='flex items-center gap-2 px-4 py-3 text-tertiary'
                        key={feature}
                      >
                        <Icon name='exclamation-circle' size='sm' />
                        <p className='text-sm font-book leading-none text-secondary'>
                          {SUPPORTED_FEATURES_TITLES[feature]}
                        </p>
                        <Badge intent='pro'>
                          {getPlanForFeature(features, feature)?.name}
                        </Badge>
                      </li>
                    ))}
                  </ul>
                </div>

                <footer className='flex justify-end p-4'>
                  <Button
                    asChild
                    intent='primary'
                    size='sm'
                    onClick={() => setOpen(false)}
                  >
                    <Link href={`${to(pathname, { page: '/plan-billing' })}`}>
                      Upgrade plan
                    </Link>
                  </Button>
                </footer>
              </motion.div>
            )}
          </AnimatePresence>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

const getPlanForFeature = (
  features: Instance['features'],
  name: string,
): InstanceFeaturePlan | null => {
  return features?.[name]?.plan || null;
};
