// Moved these permissions / supported features to app router directory to maintain one source of truth. These are also used in the pages router.
export type SupportedFeaturesKeys = keyof typeof SUPPORTED_FEATURES;
export type SupportedFeaturesValues =
  (typeof SUPPORTED_FEATURES)[SupportedFeaturesKeys];

export const SUPPORTED_FEATURES = {
  custom_email_template: 'app:custom_email_template',
  custom_sms_template: 'app:custom_sms_template',
  custom_session_duration: 'app:custom_session_duration',
  custom_session_inactivity_timeout: 'app:custom_session_inactivity_timeout',
  custom_session_token: 'app:custom_session_token',
  mfa_backup_code: 'app:mfa_backup_code',
  mfa_phone_code: 'app:mfa_phone_code',
  mfa_totp: 'app:mfa_totp',
  multi_session: 'app:multi_session',
  phone_code: 'app:phone_code',
  remove_branding: 'app:remove_branding',
  unlimited_social: 'app:unlimited_social',
  magic_link: 'app:magic_link',
  email_code: 'app:email_code',
  password: 'app:password',
  passkey: 'app:passkey',
  unlimited_integration: 'unlimited_integration',
  custom_jwt_template: 'app:custom_jwt_template',
  impersonation: 'app:impersonation',
  ban_user: 'app:ban_user',
  unlimited_org_memberships: 'unlimited_org_memberships',
  allowed_memberships: 'app:allowed_memberships',
  password_complexity: 'app:password_complexity',
  saml: 'app:saml',
  domains: 'app:domains',
  device_tracking: 'app:device_tracking',

  // restrictions
  allowlist: 'app:allowlist',
  blocklist: 'app:blocklist',
  ignore_dots_for_gmail_addresses: 'app:ignore_dots_for_gmail_addresses',

  //org features
  organization_domains: 'org:domains',
  organization_roles: 'org:roles',
  organization_permissions: 'org:permissions',
} as const;

export const SUPPORTED_FEATURES_TITLES = {
  [SUPPORTED_FEATURES.allowlist]: 'Allowlist',
  [SUPPORTED_FEATURES.organization_domains]: 'Verified organization domains',
  [SUPPORTED_FEATURES.blocklist]: 'Blocklist',
  [SUPPORTED_FEATURES.ignore_dots_for_gmail_addresses]:
    'Ignore dots for Gmail addresses',
  [SUPPORTED_FEATURES.custom_email_template]: 'Custom email templates',
  [SUPPORTED_FEATURES.custom_sms_template]: 'Custom SMS templates',
  [SUPPORTED_FEATURES.custom_session_duration]: 'Custom session duration',
  [SUPPORTED_FEATURES.custom_session_inactivity_timeout]: 'Inactivity timeout',
  [SUPPORTED_FEATURES.custom_session_token]: 'Custom session token',
  [SUPPORTED_FEATURES.mfa_backup_code]:
    'Multi factor authentication via backup code',
  [SUPPORTED_FEATURES.domains]: 'Multi-domain',
  [SUPPORTED_FEATURES.mfa_phone_code]:
    'Multi factor authentication via phone code',
  [SUPPORTED_FEATURES.mfa_totp]: 'Multi factor authentication via TOTP',
  [SUPPORTED_FEATURES.multi_session]: 'Multi session',
  [SUPPORTED_FEATURES.phone_code]: 'Phone numbers',
  [SUPPORTED_FEATURES.remove_branding]: 'Hide Clerk branding',
  [SUPPORTED_FEATURES.unlimited_social]: 'Unlimited social connections',
  [SUPPORTED_FEATURES.magic_link]: 'Email magic links',
  [SUPPORTED_FEATURES.email_code]: 'Email OTPs',
  [SUPPORTED_FEATURES.password]: 'Passwords',
  [SUPPORTED_FEATURES.passkey]: 'Passkeys',
  [SUPPORTED_FEATURES.unlimited_integration]: 'Unlimited integrations',
  [SUPPORTED_FEATURES.custom_jwt_template]: 'Custom JWT templates',
  [SUPPORTED_FEATURES.impersonation]: 'User impersonation',
  [SUPPORTED_FEATURES.ban_user]: 'Ban user',
  [SUPPORTED_FEATURES.allowed_memberships]:
    'Unlimited organization memberships',
  [SUPPORTED_FEATURES.password_complexity]: 'Advanced password complexity',
  [SUPPORTED_FEATURES.saml]: 'Enterprise Connections',
  [SUPPORTED_FEATURES.organization_roles]: 'Custom roles',
  [SUPPORTED_FEATURES.organization_permissions]: 'Custom permissions',
};

const DASHBOARD_PERMISSION_PREFIX = 'org:dash_';
export const PERMISSIONS = {
  IMPERSONATE_USERS: `${DASHBOARD_PERMISSION_PREFIX}users:imp`,
  BAN_USERS: `${DASHBOARD_PERMISSION_PREFIX}users:ban`,
  MANAGE_USERS: `${DASHBOARD_PERMISSION_PREFIX}users:m`,
  READ_USERS: `${DASHBOARD_PERMISSION_PREFIX}users:r`,

  MANAGE_ORGS: `${DASHBOARD_PERMISSION_PREFIX}orgs:m`,
  READ_ORGS: `${DASHBOARD_PERMISSION_PREFIX}orgs:r`,
  MANAGE_ORG_SETTINGS: `${DASHBOARD_PERMISSION_PREFIX}org_settings:m`,

  MANAGE_APPLICATIONS: `${DASHBOARD_PERMISSION_PREFIX}apps:m`,

  READ_BILLING: `${DASHBOARD_PERMISSION_PREFIX}billing:r`,
  MANAGE_BILLING: `${DASHBOARD_PERMISSION_PREFIX}billing:m`,

  MANAGE_DEVELOPER_RESOURCES: `${DASHBOARD_PERMISSION_PREFIX}dev_resources:m`,

  MANAGE_API_KEYS: `${DASHBOARD_PERMISSION_PREFIX}api_keys:m`,
} as const;

export const permissionTooltip =
  'You lack the necessary permissions to perform this action or access this feature.';
