type ErrorMeta = {
  param_name?: string;
  unsupported_features?: string[];
  // These are very uncommon and are not considered field level errors automatically
  param_names?: string[];
};

export interface DAPIError {
  code: string;
  message: string;
  long_message?: string;
  meta?: ErrorMeta;
}

interface FieldError extends DAPIError {
  meta: {
    param_name: string;
  };
}

export class HTTPError extends Error {
  code: number;
  fieldErrors: FieldError[];
  globalErrors: DAPIError[];
  errorMeta: ErrorMeta[];

  constructor(message: string, code: number, errorData: DAPIError[] = []) {
    super(message);

    this.code = code;
    this.name = 'HTTPError';

    Object.setPrototypeOf(this, HTTPError.prototype);

    const { fieldErrors, globalErrors } = this.parseErrorData(errorData);
    this.fieldErrors = fieldErrors;
    this.globalErrors = globalErrors;
    this.errorMeta = errorData
      .map(error => error?.meta)
      .filter((v): v is NonNullable<DAPIError['meta']> => Boolean(v));
  }

  private parseErrorData(data: DAPIError[]) {
    return (data || []).reduce<{
      fieldErrors: FieldError[];
      globalErrors: DAPIError[];
    }>(
      (memo, err) => {
        if (err.meta && err.meta.param_name) {
          memo.fieldErrors.push(err as FieldError);
        } else {
          memo.globalErrors.push(err);
        }

        return memo;
      },
      {
        fieldErrors: [],
        globalErrors: [],
      },
    );
  }
}

export function getToastErrorMessage(error: unknown, fallback: string) {
  return (
    (error instanceof HTTPError &&
      error.globalErrors
        .map(error => error.long_message || error.message)
        .join(', ')) ||
    fallback
  );
}
