import { Instance, InstanceJwtTemplate } from '@/app/api/types/instances';
import { Icon } from '@/app/components/Icon';
import { UnsupportedFeatures } from '@/app/components/banners/unsupported-features';
import { Banner } from '@/app/components/ui/Banner';
import { pluralize } from '@/app/utils/string';
import { applicationInstancePath, billingPath } from '@utils/routes';
import Link from 'next/link';
import { useParams } from 'next/navigation';

type CookieOverflowBannerConfig = {
  showBanner: boolean;
  overflowingTemplates?: InstanceJwtTemplate[];
};

type Props = {
  showAppInGracePeriodBanner: boolean;
  showExceededMAUBanner: boolean;
  showUpdatePasswordEndpointBanner: boolean;
  showInstallSDKBanner: boolean;
  cookieOverflowBanner: CookieOverflowBannerConfig;
  unsupportedFeatures: string[];
  features: Instance['features'];
};
export const BannerManager = ({
  showAppInGracePeriodBanner,
  cookieOverflowBanner,
  showExceededMAUBanner,
  showUpdatePasswordEndpointBanner,
  showInstallSDKBanner,
  unsupportedFeatures,
  features,
}: Props) => {
  const { applicationId, instanceId } = useParams<{
    applicationId: string;
    instanceId: string;
  }>()!;

  if (showAppInGracePeriodBanner && unsupportedFeatures.length > 0) {
    return (
      <Banner
        type='warning'
        message={
          <UnsupportedFeatures
            unsupportedFeatures={unsupportedFeatures}
            features={features}
          />
        }
        actions={
          <Link
            href={billingPath(applicationId, instanceId)}
            className='flex shrink-0 items-center font-semibold text-[--banner-text-color]'
          >
            Upgrade your plan{' '}
            <Icon
              name='chevron-right'
              size='sm'
              className='opacity-50 transition group-hover:translate-x-xs group-hover:opacity-100'
            />
          </Link>
        }
      />
    );
  }

  if (showExceededMAUBanner) {
    return (
      <Banner
        type='warning'
        message={
          <>
            <span className='font-semibold'>User count exceeded.</span> Upgrade
            plan to prevent service interruption.
          </>
        }
        actions={
          <Link
            href={billingPath(applicationId, instanceId)}
            className='flex shrink-0 items-center font-semibold text-[--banner-text-color]'
          >
            View plans
          </Link>
        }
      />
    );
  }

  if (showUpdatePasswordEndpointBanner) {
    return (
      <Banner
        type='warning'
        message={
          <>
            Security update: Update your application to use the new{' '}
            <span className='font-mono'>updatePassword()</span>.
          </>
        }
        actions={
          <Link
            href={applicationInstancePath({
              applicationId: applicationId,
              instanceId: instanceId,
              postfix: '/settings',
            })}
            className='flex shrink-0 items-center font-semibold text-[--banner-text-color]'
          >
            Go to Settings
          </Link>
        }
      />
    );
  }

  if (showInstallSDKBanner) {
    return (
      <Banner
        type='notice'
        message={<>Install Clerk&apos;s SDK to create your first user.</>}
        actions={
          <Link
            href={'/'}
            className='group flex shrink-0 items-center font-semibold text-[--banner-text-color]'
            data-analytics='complete-setup-banner'
          >
            Complete setup{' '}
            <Icon
              name='chevron-right'
              size='sm'
              className='opacity-50 transition group-hover:translate-x-xs group-hover:opacity-100'
            />
          </Link>
        }
      />
    );
  }

  if (cookieOverflowBanner.showBanner) {
    return (
      <Banner
        type='warning'
        message={
          <>
            <span className='font-semibold'>
              Some users are exceeding cookie size limits.
            </span>{' '}
            {cookieBannerFixMessage(
              cookieOverflowBanner.overflowingTemplates?.map(i => i.name) || [],
            )}
          </>
        }
        actions={
          <Link
            href={
              'https://clerk.com/docs/backend-requests/resources/session-tokens#size-limitations'
            }
            className='flex shrink-0 items-center font-semibold text-[--banner-text-color]'
          >
            Learn more
          </Link>
        }
      />
    );
  }

  return null;
};

const formatter = new Intl.ListFormat('en', {
  style: 'long',
  type: 'conjunction',
});

function cookieBannerFixMessage(overflowingTemplateNames: string[]): string {
  const displayNames = overflowingTemplateNames.map(t => {
    if (t === '__session') {
      // "__session" is a special template for the
      // default session token config
      return 'session token';
    }
    return '`' + t + '`';
  });
  return `Adjust your ${formatter.format(displayNames)} ${pluralize(displayNames.length, 'template', false)} to avoid disruption.`;
}
