import { IconProps } from '@/app/components/Icon';

export function GavelIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M6.59711 14.4595L5.75 17.25H14.25L13.4029 14.4595C13.275 14.0381 12.8864 13.75 12.446 13.75H7.55399C7.11359 13.75 6.72504 14.0381 6.59711 14.4595Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path
            d='M15.0354 3.93467L14.3381 3.23733C13.6883 2.58756 12.6348 2.58756 11.985 3.23733L8.23733 6.98504C7.58756 7.63481 7.58756 8.68829 8.23733 9.33805L8.93467 10.0354C9.58444 10.6852 10.6379 10.6852 11.2877 10.0354L13.1615 8.16155L15.0354 6.28769C15.6852 5.63792 15.6852 4.58444 15.0354 3.93467Z'
            fill='currentColor'
            fillOpacity={0.15}
          />
          <path d='M3.75 17.25H16.25M13.1615 8.16155L15.0354 6.28769C15.6852 5.63792 15.6852 4.58444 15.0354 3.93467L14.3381 3.23733C13.6883 2.58756 12.6348 2.58756 11.985 3.23733L8.23733 6.98504C7.58756 7.63481 7.58756 8.68829 8.23733 9.33805L8.93467 10.0354C9.58444 10.6852 10.6379 10.6852 11.2877 10.0354L13.1615 8.16155ZM13.1615 8.16155L17.25 12.25M4.25 12.75H2.75M5.34835 9.34839L4.28769 8.28773M5.75 17.25L6.59711 14.4595C6.72504 14.0381 7.11359 13.75 7.55399 13.75H12.446C12.8864 13.75 13.275 14.0381 13.4029 14.4595L14.25 17.25H5.75Z' />
        </>
      ) : (
        <>
          <path d='M4.55373 11.3589L3.75 13.25H12.25L11.4463 11.3589C11.2894 10.9897 10.9271 10.75 10.5259 10.75H5.47407C5.07294 10.75 4.71063 10.9897 4.55373 11.3589Z' />
          <path d='M2.75 13.25H13.25' />
          <path d='M10.883 5.883L11.9679 4.79814C12.344 4.42196 12.344 3.81205 11.9679 3.43586L11.5641 3.03214C11.188 2.65595 10.578 2.65595 10.2019 3.03214L8.03214 5.20186C7.65595 5.57804 7.65595 6.18796 8.03214 6.56414L8.43586 6.96786C8.81204 7.34405 9.42195 7.34405 9.79814 6.96786L10.883 5.883ZM10.883 5.883L13.25 8.25' />
          <path d='M4.25 8L2.75 8' />
          <path d='M5.34836 5.34839L4.2877 4.28773' />
        </>
      )}
    </>
  );
}
