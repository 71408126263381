'use client';

import { VariantProps, cva, cx } from 'cva';
import { AnimatePresence, motion } from 'motion/react';
import { useState } from 'react';
import { Button, Tooltip, TooltipTrigger } from 'react-aria-components';

// @ts-expect-error https://github.com/adobe/react-spectrum/issues/7494
const MotionTooltip = motion.create(Tooltip);

export interface BadgeProps extends VariantProps<typeof badgeVariants> {
  className?: string;
  children: React.ReactNode;
  tooltip?: string;
}

const badgeVariants = cva({
  base: [
    'relative isolate inline-flex flex-none items-center rounded-sm bg-clip-border',
    'after:absolute after:inset-0 after:rounded-inherit after:bg-gradient-to-b after:from-transparent after:to-black after:opacity-2',
  ],
  variants: {
    intent: {
      secondary: 'border-gray-500 bg-gray-200 text-gray-1000',
      success: 'border-green-500 bg-green-200 text-green-1000',
      warning: 'border-orange-500 bg-orange-200 text-orange-1000',
      danger: 'border-red-500 bg-red-200 text-red-1000',
      primary: 'border-purple-500 bg-purple-200 text-purple-1000',
      beta: 'bg-blue-200 text-blue-1000',
      slate: [
        'border-black/[0.16] bg-gray-900 text-white',
        'before:absolute before:inset-0 before:rounded-inherit before:bg-black/8',
      ],
      pro: [
        'overflow-hidden bg-gray-1200 text-white shadow-[0_1px_2px,0_1px_2px] shadow-black/10',
        'before:absolute before:inset-0 before:rounded-inherit before:shadow-[inset_0_1px_0,inset_0_0_0_1px] before:shadow-white/10',
      ],
      'add-on':
        'border border-dashed border-blue-700 bg-blue-200 text-blue-1000',
    },
  },
  compoundVariants: [
    {
      intent: [
        'secondary',
        'success',
        'warning',
        'danger',
        'primary',
        'slate',
        'add-on',
      ],
      class: 'border px-[0.3125rem] py-px',
    },
    {
      intent: 'pro',
      class: 'px-1.5 py-0.5',
    },
    {
      intent: 'beta',
      class: 'px-1',
    },
  ],
});

export function Badge(props: BadgeProps) {
  const [isOpen, setOpen] = useState(false);
  const badgeContent = (
    <span
      className={cx(props.className, badgeVariants({ intent: props.intent }))}
    >
      {props.intent === 'pro' && <ProBadgeEffect />}
      {props.intent === 'beta' && <BetaBadgeEffect />}
      <span className='whitesapace-nowrap relative text-xs font-medium'>
        {props.children}
      </span>
    </span>
  );

  if (!props.tooltip) {
    return badgeContent;
  }

  return (
    <TooltipTrigger
      delay={300}
      closeDelay={300}
      isOpen={isOpen}
      onOpenChange={setOpen}
    >
      <Button
        className={cx(
          'relative inline-flex rounded-[0.375rem]',
          'after:absolute after:-inset-1 after:rounded-inherit after:border-2 after:border-blue after:opacity-0',
          'focus-visible:after:opacity-100',
        )}
      >
        {badgeContent}
      </Button>

      <AnimatePresence>
        {isOpen && (
          <MotionTooltip
            className='max-w-56 rounded-md bg-gray-1200 px-1.5 py-1 text-2xs font-medium text-white shadow-lg will-change-transform dark:bg-gray-100'
            offset={5}
            initial={{ opacity: 0, scale: 0.96, y: 4 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
          >
            {props.tooltip}
          </MotionTooltip>
        )}
      </AnimatePresence>
    </TooltipTrigger>
  );
}

function ProBadgeEffect() {
  return (
    <motion.div
      className='absolute inset-y-0 right-full w-full bg-[linear-gradient(60deg,transparent,rgba(255,255,255,0.4)_50%,transparent_51%)] dark:bg-[linear-gradient(60deg,transparent,rgba(255,255,255,0.1)_50%,transparent_51%)]'
      animate={{ transform: 'translateX(200%)' }}
      transition={{ duration: 0.2, repeat: Infinity, repeatDelay: 5 }}
    />
  );
}

function BetaBadgeEffect() {
  return (
    <div className='absolute inset-0'>
      <Line
        dashArray='3.4 1'
        className='absolute -inset-x-[0.1875rem] -top-[0.03125rem] text-blue-700'
      />
      <Line
        dashArray='3.4 1'
        className='absolute -inset-x-[0.1875rem] -bottom-[0.03125rem] text-blue-700'
      />
      <Line
        vertical
        dashArray='3 1'
        className='absolute -inset-y-0.5 left-[0.5px] text-blue-700'
      />
      <Line
        vertical
        dashArray='3 1'
        className='absolute -inset-y-0.5 right-[0.5px] text-blue-700'
      />
    </div>
  );
}

function Line(props: {
  className?: string;
  dashArray?: string;
  vertical?: boolean;
}) {
  const strokeWidth = 1;
  const { dashArray = '0', vertical = false } = props;

  return (
    <span className={cx('block transform-gpu', props.className)}>
      <svg
        width={vertical ? strokeWidth : '100%'}
        height={vertical ? '100%' : strokeWidth}
      >
        <line
          x1={vertical ? strokeWidth / 2 : 0}
          y1={vertical ? 0 : strokeWidth / 2}
          x2={vertical ? strokeWidth / 2 : '100%'}
          y2={vertical ? '100%' : strokeWidth / 2}
          style={{
            stroke: 'currentColor',
            strokeDasharray: dashArray,
            strokeWidth: strokeWidth,
          }}
        />
      </svg>
    </span>
  );
}
