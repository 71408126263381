import {
  Application,
  CreateProductionInstance,
} from '@/app/api/types/applications';
import {
  CurrentSubscription,
  ListProductResponse,
} from '@/app/api/types/products';
import { request } from '@/app/utils/http';
import { queryOptions } from '@tanstack/react-query';
import { Instance } from './types/instances';
import { GetTokenOptions } from './types/utils';

export const getApplications = (
  sessionId: string,
  organizationId: string | null | undefined,
  { getToken }: GetTokenOptions,
) =>
  request<Application[]>({
    // TODO: Remove sessionId and organizationId?
    // According to a comment on the old function in pages, sessionId and
    // organizationId was only appended here to cache-bust SWR. Since for
    // queries we include them in a queryKey we should not need them here.
    path: `/v1/applications?session=${sessionId}&organizationId=${organizationId}`,
    getToken,
  });

export const applicationsQuery = (
  sessionId: string,
  organizationId: string | null | undefined,
  { getToken }: GetTokenOptions,
) => {
  return queryOptions({
    queryFn: () => {
      return getApplications(sessionId, organizationId, {
        getToken,
      });
    },
    queryKey: ['applications', sessionId, organizationId],
  });
};

export const getApplication = (
  applicationId: string,
  { getToken }: GetTokenOptions,
) =>
  request<Application>({
    path: `/v1/applications/${applicationId}`,
    getToken,
  });
export const applicationQuery = (
  applicationId: string,
  { getToken }: GetTokenOptions,
) => {
  return queryOptions({
    queryFn: () => getApplication(applicationId, { getToken }),
    queryKey: ['application', applicationId],
  });
};

export const patchApplication = (
  applicationId: string,
  data: Partial<Application>,
  { getToken }: GetTokenOptions,
) =>
  request<Application>({
    data,
    method: 'PATCH',
    path: `/v1/applications/${applicationId}`,
    getToken,
  });

export const currentSubscriptionQuery = (
  applicationId: string,
  { getToken }: GetTokenOptions,
) => {
  return queryOptions({
    queryFn: () =>
      request<CurrentSubscription>({
        path: `/v1/applications/${applicationId}/current_subscription`,
        getToken,
      }),
    queryKey: ['currentSubscription', applicationId],
  });
};

export const subscriptionPlansQuery = (
  applicationId: string,
  { getToken }: GetTokenOptions,
) => {
  return queryOptions({
    queryFn: () =>
      request<ListProductResponse>({
        path: `/v1/applications/${applicationId}/subscription_plans`,
        getToken,
      }),
    queryKey: ['subscriptionPlans', applicationId],
  });
};

export const customerPortalSession = (
  applicationId: string,
  data: { returnUrl: string },
  { getToken }: GetTokenOptions,
) =>
  request<{ url: string }>({
    data,
    method: 'POST',
    path: `/v1/applications/${applicationId}/customer_portal_session`,
    getToken,
  });

export const checkoutSession = (
  applicationId: string,
  data: { plans: string[]; return_url: string },
  { getToken }: GetTokenOptions,
) =>
  request<{ url: string }>({
    data,
    method: 'POST',
    path: `/v1/applications/${applicationId}/checkout/session`,
    getToken,
  });

export const validateCheckout = (
  applicationId: string,
  data: { plans: string[]; with_refund: boolean },
  { getToken }: GetTokenOptions,
) =>
  queryOptions({
    queryFn: () =>
      request<{ refund_amount: number; unsupported_features: string[] }>({
        data,
        method: 'POST',
        path: `/v1/applications/${applicationId}/checkout/validate`,
        getToken,
      }),
    queryKey: ['validateCheckoutSession', applicationId, data.plans],
  });

export const addProduct = (
  applicationId: string,
  productId: string,
  { getToken }: GetTokenOptions,
) =>
  request({
    method: 'POST',
    path: `/v1/applications/${applicationId}/products/${productId}`,
    getToken,
  });

export const removeProduct = (
  applicationId: string,
  productId: string,
  { getToken }: GetTokenOptions,
) =>
  request({
    method: 'DELETE',
    path: `/v1/applications/${applicationId}/products/${productId}`,
    getToken,
  });

export const refreshPaymentStatus = (
  applicationId: string,
  data: { checkout_session_id: string },
  { getToken }: GetTokenOptions,
) =>
  request<{ id: string }>({
    data,
    method: 'POST',
    path: `/v1/applications/${applicationId}/refresh_payment_status`,
    getToken,
  });

export const validateCloning = (
  applicationId: string,
  clone_instance_id: string,
  { getToken }: GetTokenOptions,
) =>
  request<never>({
    method: 'POST',
    path: `/v1/applications/${applicationId}/validate_cloning`,
    data: {
      clone_instance_id,
    },
    getToken,
  });

export const createProductionInstance = (
  applicationId: string,
  { clone_instance_id, home_url, is_secondary }: CreateProductionInstance,
  { getToken }: GetTokenOptions,
) =>
  request<Instance>({
    method: 'POST',
    path: `/v1/applications/${applicationId}/production_instance`,
    data: {
      clone_instance_id,
      home_url,
      is_secondary,
    },
    getToken,
  });

export const claimAccountlessApplication = async (
  { token: claimToken, name }: { token: string; name: string },
  { getToken }: GetTokenOptions,
) =>
  request<Application>({
    method: 'POST',
    path: '/v1/accountless_applications/claim',
    data: {
      token: claimToken,
      name,
    },
    getToken,
  });
