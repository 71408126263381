import { IconProps } from '@/app/components/Icon';

export function OkxWalletIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path
            d='M1 1V19H19V1H1ZM4.89455 5.15773C4.89455 5.03279 4.99823 4.92911 5.12317 4.92911H8.07133C8.19628 4.92911 8.29996 5.03279 8.29996 5.15773V8.10589C8.29996 8.2335 8.19628 8.33717 8.07133 8.33717H5.12317C4.99823 8.33717 4.89455 8.2335 4.89455 8.10589V5.15773ZM8.29996 14.8423C8.29996 14.9672 8.19628 15.0709 8.07133 15.0709H5.12317C4.99823 15.0709 4.89455 14.9672 4.89455 14.8423V11.8941C4.89455 11.7665 4.99823 11.6628 5.12317 11.6628H8.07133C8.19628 11.6628 8.29996 11.7665 8.29996 11.8941V14.8423ZM11.4741 11.7027H8.52592C8.40097 11.7027 8.2973 11.599 8.2973 11.4714V8.52592C8.2973 8.39832 8.39832 8.29464 8.52592 8.29464H11.4741C11.6017 8.29464 11.7054 8.39832 11.7054 8.52592V11.4714C11.7054 11.599 11.6017 11.7027 11.4741 11.7027ZM15.1108 14.8423C15.1108 14.9672 15.0071 15.0709 14.8795 15.0709H11.934C11.8064 15.0709 11.7027 14.9672 11.7027 14.8423V11.8941C11.7027 11.7665 11.8064 11.6628 11.934 11.6628H14.8795C15.0071 11.6628 15.1108 11.7665 15.1108 11.8941V14.8423ZM15.1108 8.10323C15.1108 8.23084 15.0071 8.33451 14.8795 8.33451H11.934C11.8064 8.33451 11.7027 8.23084 11.7027 8.10323V5.15773C11.7027 5.03013 11.8064 4.92645 11.934 4.92645H14.8795C15.0071 4.92645 15.1108 5.03013 15.1108 5.15773V8.10323Z'
            fill='black'
            stroke='none'
          />
        </>
      ) : (
        <>
          <path
            d='M1 1V15H15V1H1ZM4.02909 4.23379C4.02909 4.13661 4.10973 4.05597 4.20691 4.05597H6.49993C6.59711 4.05597 6.67774 4.13661 6.67774 4.23379V6.52681C6.67774 6.62605 6.59711 6.70669 6.49993 6.70669H4.20691C4.10973 6.70669 4.02909 6.62605 4.02909 6.52681V4.23379ZM6.67774 11.7662C6.67774 11.8634 6.59711 11.944 6.49993 11.944H4.20691C4.10973 11.944 4.02909 11.8634 4.02909 11.7662V9.47319C4.02909 9.37395 4.10973 9.29331 4.20691 9.29331H6.49993C6.59711 9.29331 6.67774 9.37395 6.67774 9.47319V11.7662ZM9.14651 9.32432H6.85349C6.75631 9.32432 6.67568 9.24369 6.67568 9.14444V6.85349C6.67568 6.75425 6.75425 6.67361 6.85349 6.67361H9.14651C9.24575 6.67361 9.32639 6.75425 9.32639 6.85349V9.14444C9.32639 9.24369 9.24575 9.32432 9.14651 9.32432ZM11.975 11.7662C11.975 11.8634 11.8944 11.944 11.7952 11.944H9.50421C9.40496 11.944 9.32432 11.8634 9.32432 11.7662V9.47319C9.32432 9.37395 9.40496 9.29331 9.50421 9.29331H11.7952C11.8944 9.29331 11.975 9.37395 11.975 9.47319V11.7662ZM11.975 6.52474C11.975 6.62398 11.8944 6.70462 11.7952 6.70462H9.50421C9.40496 6.70462 9.32432 6.62398 9.32432 6.52474V4.23379C9.32432 4.13454 9.40496 4.05391 9.50421 4.05391H11.7952C11.8944 4.05391 11.975 4.13454 11.975 4.23379V6.52474Z'
            fill='black'
            stroke='none'
          />
        </>
      )}
    </>
  );
}
