'use client';

import { Icon } from '@/app/components/Icon';
import { useAnalytics } from '@/app/components/analytics/Analytics';
import { DialogNext } from '@/app/components/ui/DialogNext';
import { cx } from 'cva';
import Link from 'next/link';
import DiscordIcon from './ui/DialogNextIcons/DiscordIcon';
import DocumentationIcon from './ui/DialogNextIcons/DocumentationIcon';
import FeatureRequestIcon from './ui/DialogNextIcons/FeatureRequestIcon';
import SalesCallIcon from './ui/DialogNextIcons/SalesCallIcon';
import SupportIcon from './ui/DialogNextIcons/SupportIcon';

export function HelpCenter() {
  return (
    <DialogNext
      title='How can we help?'
      description='Choose an option based on your needs.'
      trigger={
        <button
          data-analytics='help-modal-opened'
          className={cx(
            'rounded p-1.5 text-gray-1100 transition hover:bg-gray-300 hover:text-gray-1200',
            'focus:bg-gray-300 focus:text-gray-1200 focus:ring-2 focus:ring-blue focus:ring-offset-2',
          )}
        >
          <Icon name='bubble-question' size='base' />
        </button>
      }
    >
      <div className='grid grid-cols-6 gap-3 text-center font-medium'>
        <HelpItemLink
          icon={<DocumentationIcon />}
          title='Documentation'
          href='https://clerk.com/docs'
        />
        <HelpItemLink
          icon={<SupportIcon />}
          title='Support'
          href='https://clerk.com/contact/support'
        />
        <HelpItemLink
          icon={<FeatureRequestIcon />}
          title='Feature request'
          href='https://feedback.clerk.com/roadmap'
        />
        <HelpItemLink
          icon={<SalesCallIcon />}
          title='Contact sales'
          href='https://clerk.com/contact/sales'
        />
        <HelpItemLink
          icon={<DiscordIcon />}
          title='Discord'
          href='https://discord.gg/b5rXHjAg7A'
        />
      </div>
    </DialogNext>
  );
}

function HelpItemLink(props: {
  icon: React.ReactNode;
  title: string;
  href: string;
  className?: string;
}) {
  const { track } = useAnalytics();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    track('Dashboard Help Clicked', {
      surface: 'Dashboard',
      help_type: props.title,
      href: props.href,
    });

    // Delay the opening of the link to allow the track event to be sent
    setTimeout(() => {
      window.open(props.href, '_blank');
    }, 300);
  };

  return (
    <DialogNext.Action>
      <Link
        target='_blank'
        href={props.href}
        onClick={handleClick}
        className={cx(
          props.className,
          'first:col-span-full first:bg-purple-900/2 first:hover:border-purple-900/10 first:hover:bg-purple-900/4 first:sm:col-span-3',
          '[&:nth-child(2)]:col-span-3 sm:[&:nth-child(2)]:bg-purple-900/2 sm:[&:nth-child(2)]:hover:border-purple-900/10 sm:[&:nth-child(2)]:hover:bg-purple-900/4',
          'group col-span-3 flex flex-col items-center gap-2 rounded-md border pb-4 pt-4.5 transition hover:bg-gray-100 sm:col-span-2',
          'focus:bg-gray-100 focus:text-gray-1200 focus:ring-2 focus:ring-blue focus:ring-offset-2',
        )}
      >
        <div
          className={cx(
            'transform-gpu text-gray-900 transition group-hover:text-gray-1100',
          )}
        >
          {props.icon}
        </div>
        {props.title}
      </Link>
    </DialogNext.Action>
  );
}
