'use client';

import { mergeRefs } from '@/app/utils/merge-refs';
import { cva, type VariantProps } from 'cva';
import { forwardRef, type HTMLAttributes } from 'react';

const skeleton = cva({
  base: 'flex h-[--skeleton-height,fit-content] w-[--skeleton-width,fit-content] cursor-progress',
  compoundVariants: [
    {
      className: 'bg-gray-300 dark:bg-gray-800',
      loading: true,
    },
  ],
  variants: {
    loading: {
      false: 'bg-transparent',
      true: 'animate-pulse select-none text-transparent *:invisible *:opacity-0',
    },
    rounded: {
      full: 'rounded-full',
      lg: 'rounded-lg',
      md: 'rounded-md',
      sm: 'rounded-sm',
      xl: 'rounded-xl',
    },
  },
});

type SkeletonRef = HTMLDivElement;

/**
 * # Skeleton
 *
 * ## Usage
 *
 * Should be displayed whilst another component is loading.
 *
 * Can be used as either fixed size with `height` and `width` props or as a
 * wrapper around other components.
 *
 * ## Follow-up Tasks
 *
 * https://linear.app/clerk/issue/DES-1079/skeleton-follow-up
 */
export const Skeleton = forwardRef(function SkeletonComponent(
  {
    children,
    className,
    loading = true,
    height,
    width,
    rounded = 'sm',
  }: Pick<HTMLAttributes<SkeletonRef>, 'children' | 'className'> & {
    height?: number | string;
    width?: number | string;
  } & VariantProps<typeof skeleton>,
  ref: React.Ref<SkeletonRef>,
) {
  return (
    <span
      ref={mergeRefs([
        // @ts-expect-error we can replace this with inert once it's supported (react 19)
        node =>
          node &&
          (loading
            ? node.setAttribute('inert', '')
            : node.removeAttribute('inert')),
        ref,
      ])}
      className={skeleton({
        className,
        loading,
        rounded,
      })}
      style={
        {
          '--skeleton-height':
            typeof height === 'number' ? `${height / 16}rem` : height,
          '--skeleton-width':
            typeof width === 'number' ? `${width / 16}rem` : width,
        } as React.CSSProperties
      }
    >
      {children}
    </span>
  );
});
