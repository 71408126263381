import { Section } from './Section';
import { Badge } from '@components/planAndBilling/Badge';
import { Number } from '@clerk/ceramic/experimental/components/Number';
import { format, parseISO } from 'date-fns';
import { Dispatch } from 'react';
import { ApplicationSubscription } from '@dapi/currentOrganization/types';
import { priceFromCentsToDollars } from '@components/planAndBilling/utils';
import {
  PRO_PLAN_ID,
  FREE_PLAN_ID,
} from '@components/planAndBilling/currentSubscription/constants';
import { cx } from 'cva';
import { DATE_FORMAT } from '@components/planAndBilling/constants';
import { Tooltip } from '@components/planAndBilling/organization/Tooltip';
import { Icon } from '@clerk/ceramic/experimental/components/Icon';

const ApplicationRow = ({
  application,
  onSelectApplication,
}: {
  application: ApplicationSubscription;
  onSelectApplication: Dispatch<ApplicationSubscription>;
}) => {
  const enabledAddons = application.addons.filter(addon => addon.is_subscribed);
  return (
    <tr
      key={application.name}
      className='group isolate hover:cursor-pointer [&>td]:border-b [&>td]:border-b-legacyGray-100 [&>td]:px-4 [&>td]:py-2.5 [&>td]:last:border-none'
      onClick={() => onSelectApplication(application)}
      aria-label={`Billing info for application ${application.name}`}
    >
      <td className='group-hover:bg-legacyGray-25'>
        <div className='inline-flex items-center gap-2 text-base/[1.125rem] font-medium text-legacyGray-950'>
          <div className='relative aspect-square h-2.5 rounded-full bg-legacy-purple-500 before:absolute before:inset-0 before:rounded-full before:border before:border-black/12' />
          <span className='max-w-[13rem] truncate'>{application.name}</span>
          <div className='hidden items-center gap-2 sm:flex'>
            <Badge
              color={
                application.plan.id === PRO_PLAN_ID ? 'skyblue' : 'legacyGray'
              }
            >
              {application.plan.name}
            </Badge>
            {enabledAddons.length > 0 && (
              <Badge color='spectrum'>{`+ ${enabledAddons.length} add-ons`}</Badge>
            )}
          </div>
        </div>
      </td>
      <td className='text-left text-secondary group-hover:bg-legacyGray-25'>
        {application.plan.id === FREE_PLAN_ID
          ? '-'
          : format(
              parseISO(application?.billing_cycle?.end as string),
              DATE_FORMAT,
            )}
      </td>
      <td className='text-right group-hover:bg-legacyGray-25'>
        <Number
          prefix='$'
          className='text-secondary'
          aria-label={`Total amount owed for ${application.name} excluding tax`}
        >
          {priceFromCentsToDollars(application.total_amount_excluding_tax)}
        </Number>
      </td>
    </tr>
  );
};

export const ApplicationsSection = ({
  onSelectApplication,
  applications,
}: {
  onSelectApplication: (
    applicationSubscription: ApplicationSubscription,
  ) => void;
  applications: ApplicationSubscription[];
}) => {
  return (
    <Section title='Applications'>
      {applications.length > 0 ? (
        <div
          className={cx(
            'relative',
            'before:absolute before:-inset-x-px before:-bottom-px before:top-[calc(theme(fontSize.base[1].lineHeight)+theme(spacing.2))] before:-z-1 before:rounded-lg before:border before:border-black/[0.07] before:bg-white before:bg-clip-padding before:shadow-[0_1px_0_-1px,0_1px_1px] before:shadow-black/3 before:transition-transform before:duration-200 before:ease-in-out',
          )}
        >
          <table className='w-full border-separate border-spacing-0'>
            <thead className='[&_th]:px-4 [&_th]:pb-2'>
              <tr>
                <th className='text-left font-normal text-legacyGray-500'>
                  Name
                </th>
                <th className='text-left font-normal text-legacyGray-500'>
                  Next bill date
                </th>
                <th className='text-right font-normal text-legacyGray-500'>
                  <Tooltip
                    arrowSide='top'
                    content={
                      <p className='font-medium text-legacyGray-500'>
                        The amount here does not reflect any applied credit or
                        previous unpaid balances, as well as exclude taxes.
                      </p>
                    }
                  >
                    <span className='inline-flex items-center gap-1'>
                      <span>Amount</span>
                      <span className='text-tertiary'>
                        <Icon name='information-square-legacy' size='sm' />
                      </span>
                    </span>
                  </Tooltip>
                </th>
              </tr>
            </thead>

            <tbody className='first:[&>td]:first:[&>tr]:rounded-tl-lg last:[&>td]:last:[&>tr]:rounded-br-lg first:[&>td]:last:[&>tr]:rounded-bl-lg last:[&>td]:first:[&>tr]:rounded-tr-lg'>
              {applications.map(application => (
                <ApplicationRow
                  key={application.application_id}
                  application={application}
                  onSelectApplication={onSelectApplication}
                />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className='text-center text-legacyGray-500'>No applications</div>
      )}
    </Section>
  );
};
