'use client';

import { cva, cx, type VariantProps } from 'cva';
import { forwardRef, type HTMLAttributes } from 'react';

const SIZE = 16;

type SpinnerRef = HTMLDivElement;

const spinner = cva({
  base: 'relative isolate *:size-full',
  variants: {
    color: {
      current: 'text-current',
      purple: 'text-legacy-purple',
      primary: 'text-primary',
      secondary: 'text-secondary',
      tertiary: 'text-tertiary',
    },
    speed: {
      slow: '[--spinner-thumb-animation-duration:1.5s]',
      fast: '[--spinner-thumb-animation-duration:1s]',
    },
    size: {
      sm: 'size-4',
      base: 'size-5',
      lg: 'size-6',
      jumbo: [
        '[--spinner-stroke:1]',
        '[--spinner-dots-stroke-dasharray:0_3.315]',
        'size-12',
      ],
    },
  },
});

/**
 * # Spinner
 *
 * ## Variants
 *
 * - `color` (optional): defaults to the value of `currentColor`
 * - `size` (optional): defaults to `base`
 * - `speed` (optional): defaults to `fast`
 *
 * ## Accessibility
 *
 * By default, the `Spinner` will announce itself as "Loading…" to screen
 * readers. This can be overridden via `children`:
 *
 * ```tsx
 * import { Spinner } from '@clerk/ceramic/components/Spinner';
 *
 * function Example() {
 *   return (
 *     <Spinner>
 *       Some other loading text…
 *     </Spinner>
 *   );
 * }
 * ```
 *
 * ## Implementation Notes
 *
 * **@joe-bell (2024-03-27)**
 *
 * - `motion/react` automatically sets a `transform-origin` of `8px 8px`, but
 *   this doesn't play nicely with browser zoom.
 *   To fix this, we manually override to a `rem`-based value that's half of
 *   the `viewBox` size.
 * - I explored creating the dots with `strokeDasharray` and `strokeDashoffset`,
 *   but it behaved inconsistently across browsers, particularly in Safari.
 *   Instead, we swap out the `circle` elements specifically for the `jumbo`
 *   size to bump from 8 dots to 10 dots.
 */
export const Spinner = forwardRef(function Spinner(
  {
    children = 'Loading…',
    className,
    color = 'current',
    size = 'base',
    speed = 'fast',
  }: Pick<HTMLAttributes<SpinnerRef>, 'className'> &
    VariantProps<typeof spinner> & {
      children?: string;
    },
  ref: React.ForwardedRef<SpinnerRef>,
) {
  return (
    <div ref={ref} className={spinner({ color, size, speed, className })}>
      <span className='sr-only'>{children}</span>

      <svg
        viewBox={[0, 0, SIZE, SIZE].join(' ')}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={{
          ['--spinner-origin' as string]: Array.from({ length: 2 })
            .fill(`${16 / SIZE / 2}rem`)
            .join(' '),
        }}
      >
        <g
          className={cx(
            'motion-safe:origin-[--spinner-origin]',
            'motion-safe:will-change-transform',
            'motion-safe:animate-spin',
            'motion-safe:[animation-direction:reverse]',
            'motion-safe:[animation-duration:12s]',
          )}
          fill='currentColor'
          opacity={0.5}
        >
          {size === 'jumbo' ? (
            <>
              <circle cx='8' cy='2.75' r='0.5' />
              <circle cx='8' cy='13.25' r='0.5' />
              <circle cx='3.01001' cy='6.35999' r='0.5' />
              <circle cx='12.98' cy='6.35999' r='0.5' />
              <circle cx='3.01001' cy='9.62' r='0.5' />
              <circle cx='12.99' cy='9.62' r='0.5' />
              <circle cx='4.91998' cy='3.75' r='0.5' />
              <circle cx='11.08' cy='3.75' r='0.5' />
              <circle cx='4.91998' cy='12.25' r='0.5' />
              <circle cx='11.08' cy='12.25' r='0.5' />
            </>
          ) : (
            <>
              <circle cx='8' cy='2.75' r='0.75' />
              <circle cx='13.25' cy='8' r='0.75' />
              <circle cx='2.75' cy='8' r='0.75' />
              <circle cx='4.29999' cy='4.29001' r='0.75' />
              <circle cx='11.7' cy='4.29001' r='0.75' />
              <circle cx='4.29999' cy='11.72' r='0.75' />
              <circle cx='11.7' cy='11.72' r='0.75' />
              <circle cx='8' cy='13.25' r='0.75' />
            </>
          )}
        </g>

        <circle
          className={cx(
            'motion-safe:origin-[--spinner-origin]',
            'motion-safe:will-change-transform',
            'motion-safe:animate-spin',
            'motion-safe:[animation-duration:--spinner-thumb-animation-duration]',
          )}
          cx='8'
          cy='8'
          r='5.25'
          pathLength={360}
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          // Manually offset an additional 10 deg (on top of 90deg) to cover the
          // dots at either end of the stroke; which is particularly noticeable
          // when "reduce motion" is enabled.
          strokeDashoffset={100}
          strokeDasharray='90 270'
          strokeWidth='var(--spinner-stroke,1.5)'
        />
      </svg>
    </div>
  );
});
